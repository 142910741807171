import { isEmpty, resolveKeysInObjectsArrays } from 'utils/browserHelper'
import { getFormattedDate, dayDiff } from 'utils/dateHelper'
import {
    GENDER_TITLE_MAPPING,
    TITLE_GENDER_MAPPING,
    DOMAIN,
    itineraryStatusForUserRetention,
    fareCardVasTypeList
} from 'constants/base'
import { getCurrencyByLocale } from 'utils/currencyHelper'
import { allCountryList, nationalIdPrefferedList } from 'pages/itinerary/Travellers/countryList'
import { benifits } from 'constants/benifits'

import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'
import langTranslate from 'locale'
export const onlyNumbersRegex = /^[0-9]+$/
export const onlyAlphabetsRegex = /^[A-Za-z]+$/
export const alphaNumeric = /^[0-9a-zA-Z]+$/
export const onlyAlphabetsSpaceRegex = /^[A-Za-z\s()]+$/
export const onlyArabicCharactersRegex = /^[\u0600-\u06ff ]+$/
export const validateEmail = email => {
    const pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    return pattern.test(email)
}

export const validatePassword = password => {
    return !isEmpty(password)
}

export const validateMobileNumber = (mobile, minLength, maxLength) => {
    if (mobile && mobile.length >= minLength && mobile.length <= maxLength && onlyNumbersRegex.test(mobile)) {
        return true
    }
    return false
}

export const validateFirstName = (fname, length) => {
    if (onlyAlphabetsSpaceRegex.test(fname) && fname.length <= length) {
        return true
    }
    return false
}

export const validateLastName = (lname, minLength, maxLength) => {
    if (onlyAlphabetsSpaceRegex.test(lname) && lname.length >= minLength && lname.length <= maxLength) {
        return true
    }
    return false
}

export const validateMiddleName = (mname, fname = '', maxLength) => {
    if (mname === '') return true
    if (onlyAlphabetsRegex.test(mname) && mname.length + fname.length <= maxLength) {
        return true
    }
    return false
}

export const getInsuranceDataObj = vasAncillaries => {
    const insObj = {}
    vasAncillaries &&
        vasAncillaries.forEach(ancillarie => {
            const { vasType = '', vasDetails = [] } = ancillarie
            const {
                termsAndConditions: { checked: termsIsChecked = false, mandatory: termsIsManatory } = {},
                provider,
                autoSelected,
                selected,
                startDate = '',
                endDate = ''
            } = vasDetails[0] || {}

            if (vasType === 'INTL_TRAVEL_INSURANCE') {
                insObj[vasType] = {
                    isInsuranceChecked: termsIsChecked || selected,
                    provider,
                    isMandatory: termsIsManatory,
                    noOfDays:
                        startDate && endDate
                            ? dayDiff(
                                  getFormattedDate(endDate, 'YYYY-MM-DD'),
                                  getFormattedDate(startDate, 'YYYY-MM-DD')
                              ) + 1
                            : 1,
                    isAdded: autoSelected || selected,
                    fromDate: startDate,
                    toDate: endDate
                }
            } else {
                insObj[vasType] = {
                    isInsuranceChecked: termsIsChecked || selected,
                    provider,
                    isMandatory: termsIsManatory,
                    noOfDays: null,
                    isAdded: autoSelected || selected
                }
            }
        })
    return insObj
}

export const isArabic = text => {
    let result = false
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/
    result = pattern.test(text)
    return result
}

export const createDefaultTravellerData = (itineraryData, previousItinTravellers) => {
    const { searchCriteria = {}, meta = {}, passengers = {}, itineraryStatus = '' } = itineraryData
    const getDataFromApi = itineraryStatusForUserRetention.includes(itineraryStatus)
    const { paxInfo: { adults, children, infants } = {} } = searchCriteria
    const { mandatoryFields } = meta

    let birthCountryRequired = mandatoryFields.includes('BIRTH_COUNTRY')
    let nationalityRequired = mandatoryFields.includes('NATIONALITY')
    let dobRequired = mandatoryFields.includes('DOB')
    let validationDocRequired = false
    if (
        mandatoryFields.includes('NATIONALID') ||
        mandatoryFields.includes('PASSPORT') ||
        mandatoryFields.includes('IQAMA')
    ) {
        nationalityRequired = true
        validationDocRequired = true
    }

    const travellerList = []
    for (let i = 1; i <= adults; i++) {
        travellerList.push('adult' + i)
    }
    for (let i = 1; i <= children; i++) {
        travellerList.push('child' + i)
    }

    for (let i = 1; i <= infants; i++) {
        travellerList.push('infant' + i)
    }

    let travellerData = { data: {}, validation: {} }

    travellerList.forEach((traveller, index) => {
        const travellerObj = {}
        const dataObj = {}
        const validationObj = {}

        const {
            firstName: prevFirstName = '',
            lastName: prevLastName = '',
            middleName: prevMiddleName = '',
            gender: prevGender = '',
            idProof: prevIdProof = '',
            sfDocumentIdNum: prevSfDocumentIdNum = '',
            dob: prevDOB = '',
            nationality: prevNationality = '',
            docIdNumber: prevDocIdNumber = '',
            docExpiryDate: prevDocExpiryDate = '',
            docIssuingCountry: prevDocIssuingCountry = '',
            docType: prevDocType = '',
            birthCountry: prevBirthCountry = ''
        } = (previousItinTravellers && previousItinTravellers[traveller]) || {}

        const detailsFromItinerary = passengers[index]
        // User ID
        dataObj['id'] = null
        // Duplicate User
        dataObj['duplicateUser'] = false

        // First Name
        dataObj['firstName'] = (getDataFromApi && detailsFromItinerary.firstName) || prevFirstName || ''
        validationObj['firstName'] = {
            valid: true,
            mandate: true
        }

        //Middle Name
        dataObj['middleName'] = (getDataFromApi && detailsFromItinerary.middleName) || prevMiddleName || ''
        validationObj['middleName'] = {
            valid: true,
            mandate: true
        }

        // Last Name
        dataObj['lastName'] = (getDataFromApi && detailsFromItinerary.lastName) || prevLastName || ''
        validationObj['lastName'] = {
            valid: true,
            mandate: true
        }

        // Gender
        dataObj['gender'] = (getDataFromApi && TITLE_GENDER_MAPPING[detailsFromItinerary.title]) || prevGender || ''
        validationObj['gender'] = {
            valid: true,
            mandate: true
        }

        dataObj['idProof'] = (getDataFromApi && detailsFromItinerary.docDetails?.[0]?.docType) || prevIdProof || ''
        validationObj['idProof'] = {
            valid: true,
            mandate: true
        }

        dataObj['sfDocumentIdNum'] =
            (getDataFromApi && detailsFromItinerary.docDetails?.[0]?.docNumber) || prevSfDocumentIdNum || ''
        validationObj['sfDocumentIdNum'] = {
            valid: true,
            mandate: true
        }

        // Date of Birth
        // DOB mandate for Child and Infant
        if (dobRequired || ['child', 'infant'].some(el => traveller.includes(el))) {
            let dobFromItinerary = ''
            if (detailsFromItinerary.dob) {
                dobFromItinerary = getFormattedDate(detailsFromItinerary.dob.split('T')[0], 'DD/MM/YYYY')
            }
            dataObj['dob'] = (getDataFromApi && dobFromItinerary) || prevDOB || ''

            validationObj['dob'] = {
                valid: true,
                mandate: true
            }
        }

        // Nationality
        if (nationalityRequired) {
            dataObj['nationality'] = (getDataFromApi && detailsFromItinerary.nationality) || prevNationality || ''
            validationObj['nationality'] = {
                valid: true,
                mandate: true
            }
        }

        //mandatory fields
        if (validationDocRequired) {
            if (!isEmpty(detailsFromItinerary.docDetails)) {
                dataObj['docIdNumber'] =
                    (getDataFromApi && detailsFromItinerary.docDetails[0].docNumber) || prevDocIdNumber || ''
                dataObj['docExpiryDate'] =
                    getDataFromApi && detailsFromItinerary.docDetails[0].expiryDate
                        ? getFormattedDate(detailsFromItinerary.docDetails[0].expiryDate.split('T')[0], 'DD/MM/YYYY')
                        : prevDocExpiryDate || ''
                let docType = detailsFromItinerary.docDetails[0].docType || ''
                if (docType === 'PASSPORT') {
                    docType = 'Passport'
                    dataObj['docIssuingCountry'] =
                        (getDataFromApi && detailsFromItinerary.docDetails[0].countryIssued) ||
                        prevDocIssuingCountry ||
                        ''
                } else if (docType === 'IQAMA') {
                    docType = 'Iqama ID'
                } else if (docType === 'NATIONALID') {
                    docType = 'National ID'
                    dataObj['docIssuingCountry'] =
                        (getDataFromApi && detailsFromItinerary.docDetails[0].countryIssued) ||
                        prevDocIssuingCountry ||
                        ''
                }
                dataObj['docType'] = (getDataFromApi && docType) || prevDocType
            } else if (detailsFromItinerary.passportNumber) {
                dataObj['docIdNumber'] = detailsFromItinerary.passportNumber || ''
                dataObj['docExpiryDate'] = detailsFromItinerary.passportExpiryDate
                    ? getFormattedDate(detailsFromItinerary.passportExpiryDate.split('T')[0], 'DD/MM/YYYY') || ''
                    : ''
                dataObj['docIssuingCountry'] = detailsFromItinerary.passportIssuingCountryName || ''
                dataObj['docType'] = 'Passport'
            } else {
                dataObj['docIdNumber'] = prevDocIdNumber || ''
                dataObj['docExpiryDate'] = prevDocExpiryDate || ''
                dataObj['docIssuingCountry'] = prevDocIssuingCountry || ''
                dataObj['docType'] = prevDocType || ''
            }

            validationObj['docIdNumber'] = {
                valid: true,
                mandate: true
            }
            validationObj['docExpiryDate'] = {
                valid: true,
                mandate: true
            }
            validationObj['docIssuingCountry'] = {
                valid: true,
                mandate: true
            }

            validationObj['docType'] = {
                valid: true,
                mandate: true
            }

            //added to show the list of Docs at traveller step
            if (mandatoryFields.includes('PASSPORT')) {
                validationObj['passport'] = {
                    valid: true,
                    mandate: true
                }
            }
            if (mandatoryFields.includes('NATIONALID')) {
                validationObj['nationalID'] = {
                    valid: true,
                    mandate: true
                }
            }
            if (mandatoryFields.includes('IQAMA')) {
                validationObj['iqamaID'] = {
                    valid: true,
                    mandate: true
                }
            }
        }

        // Birth Country
        if (birthCountryRequired) {
            dataObj['birthCountry'] = (getDataFromApi && detailsFromItinerary.birthCountry) || prevBirthCountry || ''
            validationObj['birthCountry'] = {
                valid: true,
                mandate: true
            }
        }

        // TODO-AB: FF number
        // Frequent Flyer Number
        // dataObj['ffAirline'] = ''
        // dataObj['ffNumber'] = ''
        // validationObj['ffAirline'] = {
        //     valid: true,
        //     mandate: true
        // }
        // validationObj['ffNumber'] = {
        //     valid: true,
        //     mandate: true
        // }

        //Assigning Data
        travellerObj[traveller] = dataObj
        travellerData.data = {
            ...travellerData.data,
            ...travellerObj
        }
        travellerObj[traveller] = validationObj
        travellerData.validation = {
            ...travellerData.validation,
            ...travellerObj
        }
    })
    return { travellerList, travellerData }
}

export const getPaxType = traveller => {
    let paxType = ''
    paxType = traveller.includes('adult')
        ? 'ADT'
        : traveller.includes('child')
        ? 'CHD'
        : traveller.includes('infant')
        ? 'INF'
        : ''
    return paxType
}

export const getTitle = (gender, traveller) => {
    let paxType = traveller.includes('adult')
        ? 'adult'
        : traveller.includes('child')
        ? 'child'
        : traveller.includes('infant')
        ? 'infant'
        : ''
    return GENDER_TITLE_MAPPING[gender][paxType]
}

export const genderTranslator = gender => {
    switch (gender) {
        case 'Male':
            return 'Male'
        case langTranslate('Male'):
            return 'Male'
        case 'Female':
            return 'Female'
        case langTranslate('Female'):
            return 'Female'
        case 'MALE':
            return 'Male'
        case 'FEMALE':
            return 'Female'
        default:
            return gender
    }
}

export const constructTravellerDetails = (defaultTravellerData, userData) => {
    const travellerDetails = []
    const { data: travellerData = {} } = defaultTravellerData
    const { travellers = [] } = userData
    for (let traveller in travellerData) {
        const id = travellerData[traveller]['id']
        const dataObj = {
            id
        }

        const personalDetails = {
            firstName: travellerData[traveller]['firstName'],
            lastName: travellerData[traveller]['lastName'],
            gender: genderTranslator(travellerData[traveller]['gender']),
            title: getTitle(travellerData[traveller]['gender'], traveller),
            paxType: getPaxType(traveller),
            id: null
        }
        if (travellerData[traveller]['middleName']) {
            personalDetails['middleName'] = travellerData[traveller]['middleName']
        }

        const dob = travellerData[traveller]['dob']
        if (dob) {
            personalDetails.dateOfBirth = getFormattedDate(dob, 'YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY')
        }
        const nationality = travellerData[traveller]['nationality']
        if (nationality) {
            personalDetails.nationality = nationality
            if (isArabic(nationality)) {
                let countryList = []
                countryList = allCountryList.filter(country => {
                    const countryName = country.ar
                    return countryName.indexOf(nationality) === 0
                })
                personalDetails.nationality = countryList[0].en.toLowerCase()
            } else {
                personalDetails.nationality = nationality.toLowerCase()
            }
        }
        const birthCountry = travellerData[traveller]['birthCountry']
        if (birthCountry) {
            personalDetails.birthCountry = birthCountry
            if (isArabic(birthCountry)) {
                let countryList = []
                countryList = allCountryList.filter(country => {
                    const countryName = country.ar
                    return countryName.indexOf(birthCountry) === 0
                })
                personalDetails.birthCountry = countryList[0].en.toLowerCase()
            } else {
                personalDetails.birthCountry = birthCountry.toLowerCase()
            }
        }

        const docDetails = []
        if (travellerData[traveller]['idProof']) {
            docDetails.push({
                docNumber: travellerData[traveller]['sfDocumentIdNum'],
                docType: travellerData[traveller]['idProof']
            })
        }
        const docObj = {}
        const docIdNumber = travellerData[traveller]['docIdNumber']
        if (docIdNumber) {
            let selectedDocType = travellerData[traveller]['docType']
            if (selectedDocType === 'Passport') {
                selectedDocType = 'PASSPORT'
            } else if (selectedDocType === 'National ID') {
                selectedDocType = 'NATIONALID'
            } else if (selectedDocType === 'Iqama ID') {
                selectedDocType = 'IQAMA'
            }
            docObj.docType = selectedDocType
            docObj.docNumber = docIdNumber
            docObj.id = 0
            const docIssuingCountry = travellerData[traveller]['docIssuingCountry']
            if (docIssuingCountry) {
                docObj.countryIssued = docIssuingCountry
                if (isArabic(docIssuingCountry) && (selectedDocType === 'PASSPORT' || selectedDocType === 'IQAMA')) {
                    let countryList = []
                    countryList = allCountryList.filter(country => {
                        const countryName = country.ar
                        return countryName.indexOf(docIssuingCountry) === 0
                    })
                    docObj.countryIssued = countryList[0].en.toLowerCase()
                }
                if (isArabic(docIssuingCountry) && selectedDocType === 'NATIONALID') {
                    let countryList = []
                    countryList = nationalIdPrefferedList.filter(country => {
                        const countryName = country.ar
                        return countryName.indexOf(docIssuingCountry) === 0
                    })
                    docObj.countryIssued = countryList[0].en.toLowerCase()
                }
                if (!isArabic(docIssuingCountry)) {
                    docObj.countryIssued = docIssuingCountry.toLowerCase()
                }
            }

            const docIssuedDate = travellerData[traveller]['docIssuedDate']
            if (docIssuedDate) {
                docObj.issuedDate = getFormattedDate(docIssuedDate, 'YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY')
            }

            const docExpiryDate = travellerData[traveller]['docExpiryDate']
            if (docExpiryDate) {
                docObj.expiryDate = getFormattedDate(docExpiryDate, 'YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY')
            }

            const nationality = travellerData[traveller]['nationality']
            if (nationality) {
                docObj.nationality = nationality
                if (isArabic(nationality)) {
                    let countryList = []
                    countryList = allCountryList.filter(country => {
                        const countryName = country.ar
                        return countryName.indexOf(nationality) === 0
                    })
                    docObj.nationality = countryList[0].en.toLowerCase()
                } else {
                    docObj.nationality = nationality.toLowerCase()
                }
            }

            const dob = travellerData[traveller]['dob']
            if (dob) {
                docObj.dateOfBirth = getFormattedDate(dob, 'YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY')
            }
        }

        // Check for Saved Traveller Data and modify the id
        for (let travellerObj of travellers) {
            if (id === travellerObj['id']) {
                const {
                    personalDetails: existingPersonalDetails = {},
                    docDetails: existingDocDetails = []
                } = travellerObj
                personalDetails.id = existingPersonalDetails.id
                if (docObj.hasOwnProperty(id)) {
                    docObj.id = existingDocDetails[0].id
                }
            }
        }

        if (!isEmpty(docObj)) {
            docDetails.push(docObj)
        }
        if (docDetails.length > 0) {
            dataObj.docDetails = docDetails
        }
        dataObj.personalDetails = personalDetails
        travellerDetails.push(dataObj)
    }
    return travellerDetails
}

export const getSearchUrlObj = searchCriteria => {
    if (searchCriteria.paxInfo === null) return {}
    const {
        paxInfo: { adults = '', children = '', infants = '' } = {},
        departDate,
        arrivalDate,
        international,
        from,
        to,
        sft = ''
    } = searchCriteria || {}

    return {
        adults,
        childs: children,
        infants,
        depart_date: !isEmpty(departDate) ? getFormattedDate(departDate.split('T')[0], 'DD/MM/YYYY') : '',
        return_date: !isEmpty(arrivalDate) ? getFormattedDate(arrivalDate.split('T')[0], 'DD/MM/YYYY') : '',
        intl: international ? 'y' : 'n',
        from: from || '',
        to: to || '',
        class: 'Economy',
        sd: new Date().getTime(),
        sft
    }
}

export const getMulticitySearchUrlObj = searchCriteria => {
    if (searchCriteria.paxInfo === null) return {}
    const { paxInfo: { adults = '', children = '', infants = '' } = {}, international, multiCitySearchCriterias } =
        searchCriteria || {}
    let query = {}
    for (let i = 0; i < multiCitySearchCriterias.length; i++) {
        const { departDate, from, to } = multiCitySearchCriterias[i]
        query = {
            ...query,
            adults,
            childs: children,
            infants,
            [`depart_date_${i + 1}`]: !isEmpty(departDate)
                ? getFormattedDate(departDate.split('T')[0], 'DD/MM/YYYY')
                : '',
            intl: international ? 'y' : 'n',
            [`from${i + 1}`]: from || '',
            [`to${i + 1}`]: to || '',
            class: 'Economy',
            multicity: true,
            num_legs: multiCitySearchCriterias.length,
            sd: new Date().getTime()
        }
    }
    return query
}

export const airportChangeHandler = (itiData = {}) => {
    const { searchCriteria: { from: searchCriteriaFrom, to: searchCriteriaTo } = {}, flights = [] } = itiData || {}

    const flightAirportObj = {}

    flights.forEach((flight, flightIndex) => {
        const prefix = flightIndex === 0 ? 'onward' : 'return'

        const { segments = [] } = flight || {}

        segments.forEach((segment, segmentIndex) => {
            if (segmentIndex === 0) {
                flightAirportObj[`${prefix}DepartureCode`] = segment.departAirport
                flightAirportObj[`${prefix}DepartureAirport`] = segment.departAirportIata
            }

            flightAirportObj[`${prefix}ArrivalCode`] = segment.arrivalAirport
            flightAirportObj[`${prefix}ArrivalAirport`] = segment.arrivalAirportIata
        })
    })

    let isAirportChangeFlag = false
    if (
        flights.length > 1 &&
        (searchCriteriaFrom !== flightAirportObj.onwardDepartureCode ||
            searchCriteriaTo !== flightAirportObj.onwardArrivalCode ||
            searchCriteriaFrom !== flightAirportObj.returnArrivalCode ||
            searchCriteriaTo !== flightAirportObj.returnDepartureCode)
    ) {
        isAirportChangeFlag = true
    } else if (
        searchCriteriaFrom !== flightAirportObj.onwardDepartureCode ||
        searchCriteriaTo !== flightAirportObj.onwardArrivalCode
    ) {
        isAirportChangeFlag = true
    }

    return { ...flightAirportObj, isAirportChangeFlag }
}

export const getFareObj = (flights = [], vasAncillaries, convenienceFeeDetails) => {
    let fareBlockList = []
    const isCFW = checkIsCFW(flights)
    const isSplRt = checkIsSplRt(flights)
    // console.log('isCFW', isCFW)
    if (isCFW && isSplRt) {
        flights.forEach((segment, index) => {
            let fare = nonSplRtFares(segment.fares)

            if (fare.length === 2) {
                let fares = sortInOrder(fare)
                fares.map((data, index) => {
                    fares[index].convenienceFee = convenienceFeeDetails && convenienceFeeDetails.total
                    fares[index].convFeePerTraveler = convenienceFeeDetails && convenienceFeeDetails.perTraveler
                })
                fareBlockList.push(fares)
            } else {
                const tempArr = fare
                tempArr.push(getDummyCorpFare())
                fareBlockList.push(tempArr)
            }
        })
    } else if (isCFW && !isSplRt) {
        flights.forEach((segment, index) => {
            if (segment.fares.length === 2) {
                let fares = sortInOrder(segment.fares)
                fares.map((data, index) => {
                    fares[index].convenienceFee = convenienceFeeDetails && convenienceFeeDetails.total
                    fares[index].convFeePerTraveler = convenienceFeeDetails && convenienceFeeDetails.perTraveler
                })
                fareBlockList.push(fares)
            } else {
                // create dummy and push
                const tempArr = {
                    ...segment.fares,
                    convenienceFee: convenienceFeeDetails && convenienceFeeDetails.total,
                    convFeePerTraveler: convenienceFeeDetails && convenienceFeeDetails.perTraveler
                }
                tempArr.push(getDummyCorpFare())
                fareBlockList.push(tempArr)
            }
        })
    } else {
        // Sum up segments
        let faresSum = {}
        flights.forEach((segment, index) => {
            if (index === 0) {
                faresSum = {
                    ...segment.fares[0],
                    convenienceFee: convenienceFeeDetails && convenienceFeeDetails.total,
                    convFeePerTraveler: convenienceFeeDetails && convenienceFeeDetails.perTraveler
                }
            } else {
                const { basePrice: retBasePrice, price: retPrice, taxes: retTaxes,discount:retDiscount } = segment.fares[0] // segment 1
                faresSum = {
                    ...faresSum,
                    basePrice: faresSum.basePrice + retBasePrice,
                    price: faresSum.price + retPrice,
                    taxes: faresSum.taxes + retTaxes,
                    discount: faresSum.discount + retDiscount,
                    convenienceFee: convenienceFeeDetails && convenienceFeeDetails.total,
                    convFeePerTraveler: convenienceFeeDetails && convenienceFeeDetails.perTraveler
                }
            }
        })
        if (!isEmpty(vasAncillaries)) {
            fareBlockList.push(getSegmentFareObj([faresSum], vasAncillaries, convenienceFeeDetails))
        } else {
            fareBlockList.push([faresSum])
        }
    }
    return fareBlockList
}

export const getFareObjForFromFares = (fares, convenienceFeeDetails) => {
    let fareBlockList = []
    let faresSum = {}
    fares.forEach((fare, index) => {
        if (index === 0) {
            faresSum = {
                ...fare,
                convenienceFee: convenienceFeeDetails && convenienceFeeDetails.total,
                convFeePerTraveler: convenienceFeeDetails && convenienceFeeDetails.perTraveler
            }
        } else {
            const { basePrice: retBasePrice, price: retPrice, taxes: retTaxes } = fare // segment 1
            faresSum = {
                ...faresSum,
                basePrice: faresSum.basePrice + retBasePrice,
                price: faresSum.price + retPrice,
                taxes: faresSum.taxes + retTaxes,
                convenienceFee: convenienceFeeDetails && convenienceFeeDetails.total,
                convFeePerTraveler: convenienceFeeDetails && convenienceFeeDetails.perTraveler
            }
        }
    })
    fareBlockList.push([faresSum])
    // if (!isEmpty(vasAncillaries)) {
    //     fareBlockList.push(getSegmentFareObj([faresSum], vasAncillaries))
    // } else {
    //     fareBlockList.push([faresSum])
    // }
    return fareBlockList
}

const sortInOrder = fares => {
    let faresArray = [...fares]
    if (faresArray[0].fareClass === 'CORPORATE') {
        ;[faresArray[0], faresArray[1]] = [faresArray[1], faresArray[0]]
    }
    return faresArray
}

const nonSplRtFares = fares => {
    let faresArray = [...fares]
    let fare = []
    let temp = {}
    for (let i = 0; i < faresArray.length; i++) {
        if (faresArray[i].fareClass !== 'SPECIAL_RT') {
            fare.push(faresArray[i])
        }
    }
    for (let i = 0; i < faresArray.length; i++) {
        if (faresArray[i].fareClass === 'SPECIAL_RT') {
            temp = { ...faresArray[i] }
        }
    }
    for (let i = 0; i < fare.length; i++) {
        if (fare[i].fareClass === 'RETAIL') {
            fare[i].splRtbasePrice = temp.basePrice || fare[i].basePrice
            fare[i].splRtprice = temp.price || fare[i].price
            fare[i].splRttaxes = temp.taxes || fare[i].taxes
            fare[i].splRtdiscount = temp.discount || fare[i].discount
            fare[i].splRtmarkup = temp.markup || fare[i].markup
            fare[i].splRtconvenienceFee = temp.convenienceFee || fare[i].convenienceFee
            fare[i].splRtfareClass = temp.fareClass || fare[i].fareClass
            fare[i].splRtfareType = temp.fareType || fare[i].fareType
            fare[i].splRtfareKey = temp.fareKey || fare[i].fareKey
        }
    }
    return fare
}
const getDummyCorpFare = () => {
    return {
        disabled: true,
        basePrice: 0,
        price: 0,
        taxes: 0,
        discount: 0,
        markup: 0,
        convenienceFee: 0,
        fareClass: 'CORPORATE',
        fareType: 'REFUNDABLE',
        fareMetaMessages: [
            {
                provider: 'Airline',
                category: 'Corporate fare',
                title: 'WORK FARE',
                messages: ['Free Amendment', 'Free Meal', 'Low Cancellation Fee']
            }
        ],
        fareKey:
            'DC_dc1|ims_y|sms_y|fn_113|supp_RADIXX|si-bc-v2-exp-6b0b6888-dc83-409c-93d9-62c7cccfd186|fk_retail_G8_113_1600887000000_AO9CBINX_true_'
    }
}

export const checkIsCFW = flights => {
    let isCFW = false
    if (flights) {
        for (let segment of flights) {
            for (let fare of segment.fares) {
                if (fare.fareClass === 'CORPORATE') {
                    isCFW = true
                    break
                }
            }
        }
    }
    return isCFW
}

export const checkIsShukranEnabled = () => {
    let isShukranEnabled = false
    if (
        DOMAIN_COUNTRY_MAP[DOMAIN] === 'ME' ||
        DOMAIN_COUNTRY_MAP[DOMAIN] === 'AE' ||
        DOMAIN_COUNTRY_MAP[DOMAIN] === 'SA' ||
        DOMAIN_COUNTRY_MAP[DOMAIN] === 'OM' ||
        DOMAIN_COUNTRY_MAP[DOMAIN] === 'KW' ||
        DOMAIN_COUNTRY_MAP[DOMAIN] === 'BH'
    ) {
        isShukranEnabled = true
    }
    return isShukranEnabled
}

export const checkIsSplRt = flights => {
    let isSplRt = false
    if (flights) {
        for (let segment of flights) {
            for (let fare of segment.fares) {
                if (fare.fareClass === 'SPECIAL_RT') {
                    isSplRt = true
                    break
                }
            }
        }
    }
    return isSplRt
}

// Only Flexifly
export const getSegmentFareObj = (segmentFares, vasAncillaries, convenienceFeeDetails) => {
    let fares = []
    let flexifyFound = false
    vasAncillaries.map((vas, index) => {
        if (
            vas.vasType === 'AMEND_INSURANCE' ||
            vas.vasType === 'INTL_FLEXIFLY' ||
            vas.vasType === 'DOM_CANCELATION_INSURANCE' ||
            vas.vasType === 'INTL_CANCELATION_INSURANCE'
        ) {
            flexifyFound = true
            if (!fares.length) {
                fares.push(segmentFares[0])
            }
            const flexiflyObj = getFlexiflyObj(segmentFares[0], vas, convenienceFeeDetails)
            fares.push(flexiflyObj)
        }
    })

    if (!flexifyFound) {
        fares.push(segmentFares[0])
    }
    if (
        (fares.length === 3 && fares[1].fareMetaMessages[0].category === 'DOM_CANCELATION_INSURANCE') ||
        (fares.length === 3 && fares[1].fareMetaMessages[0].category === 'INTL_CANCELATION_INSURANCE')
    ) {
        ;[fares[1], fares[2]] = [fares[2], fares[1]]
    }
    return fares
}

const getFlexiflyObj = (fightFare, vas, convenienceFeeDetails) => {
    const { basePrice, price: flightPrice, taxes, discount, markup } = fightFare
    const { vasDetails } = vas
    const {
        provider,
        benefits = [],
        benefitGroups = [],
        totalPremiumAmount,
        supplierPremiumAmount,
        applicableTargetGroup
    } = vasDetails?.[0] || {}
    const { total, perTraveler } = convenienceFeeDetails || {}
    return {
        basePrice,
        price: flightPrice + totalPremiumAmount,
        taxes,
        discount,
        markup,
        convenienceFee: total,
        totalPremiumAmount,
        fareClass: 'RETAIL',
        fareType: null,
        convFeePerTraveler: perTraveler,
        applicableTargetGroup,
        supplierPremiumAmount,
        fareMetaMessages: [
            {
                provider,
                category: vas.vasType,
                title:
                    vas.vasType === 'DOM_CANCELATION_INSURANCE' || vas.vasType === 'INTL_CANCELATION_INSURANCE'
                        ? getEzcancelName()
                        : getCtFlexName(),
                messages: getFlexiflyBenefits(isEmpty(benefits) ? (benefitGroups[0] || {}).benefits : benefits)
            }
        ],
        fareKey: null
    }
}

const getFlexiflyBenefits = (vasBenefits=[]) => {
    const benefitsArr = []
    vasBenefits.map((benefit, index) => {
        benefitsArr.push(benefit.title)
    })
    return benefitsArr
}

export const getCashBackPayload = flights => {
    let fares = []
    for (let segment of flights) {
        for (let fare of segment.fares) {
            const { fareKey, price, fareClass } = fare
            fares.push({ fare_key: fareKey, price: price, fare_sub_type: fareClass })
        }
    }
    return { fares: fares }
}

export const defaultCardSelected = (cardSelected, fareObj, isCFW, userStatus, vasAncillaries = []) => {
    let selected = 0
    if (cardSelected === 'cfw' && isCFW && userStatus !== 'UNVERIFIED') {
        for (let i = 0; i < fareObj.length; i++) {
            if (fareObj[i].fareClass === 'CORPORATE' && fareObj[i].disabled != true) {
                selected = i
                break
            }
        }
    } else if (cardSelected === 'normal' || userStatus === 'UNVERIFIED') {
        for (let i = 0; i < fareObj.length; i++) {
            if (fareObj[i].fareClass === 'RETAIL') {
                selected = i
                break
            }
        }
    } else {
        let selectedAncillary = ''
        vasAncillaries.forEach(vasItem => {
            if (fareCardVasTypeList.includes(vasItem.vasType)) {
                const { vasDetails = [] } = vasItem
                if (!isEmpty(vasDetails)) {
                    const isSelected = vasDetails.filter(item => {
                        return item.selected
                    })
                    if (!isEmpty(isSelected)) {
                        selectedAncillary = vasItem.vasType
                    }
                }
            }
        })
        if (!isEmpty(selectedAncillary)) {
            fareObj.forEach((fare, index) => {
                const { fareMetaMessages = [{}] } = fare
                if (fareMetaMessages && fareMetaMessages[0]?.category === selectedAncillary) {
                    selected = index
                }
            })
            return selected
        }
        // case for cheap
        let min = fareObj[0].price
        for (let i = 0; i < fareObj.length; i++) {
            if (fareObj[i].price < min && fareObj[i].disabled != true) {
                selected = i
                min = fareObj[i].price
            } else if (
                fareObj[i].price === min &&
                fareObj[i].disabled != true &&
                fareObj[i].fareClass === 'CORPORATE'
            ) {
                selected = i
            }
        }
    }
    return selected
}

export const isDefaultCardSelected = (vasAncillaries = []) => {
    let index = ''
    vasAncillaries.map(vasItem => {
        if (vasItem.vasType === 'AMEND_INSURANCE') {
            const { vasDetails = [] } = vasItem
            if (!isEmpty(vasDetails)) {
                const isSelected = vasDetails.filter(item => {
                    return item.selected
                })
                if (!isEmpty(isSelected)) {
                    index = 1
                }
            }
        } else if (vasItem.vasType === 'DOM_CANCELATION_INSURANCE') {
            const { vasDetails = [] } = vasItem
            if (!isEmpty(vasDetails)) {
                const isSelected = vasDetails.filter(item => {
                    return item.selected
                })
                if (!isEmpty(isSelected)) {
                    index = 2
                }
            }
        }
    })
    if (!isEmpty(index)) return true
}

export const getFareKeyObj = flights => {
    let corpKeys = {}
    for (let segment of flights) {
        for (let fare of segment.fares) {
            if (fare.fareClass === 'CORPORATE') {
                const { fareKey } = fare
                corpKeys[fareKey] = fare.fareKey
            }
        }
    }
    return corpKeys
}

export const getfareKeyDetails = flights => {
    let Keys = {}
    let fareBlock = []
    const isSplRt = checkIsSplRt(flights)

    for (let segment of flights) {
        for (let fare of segment.fares) {
            const { fareKey, fareClass, basePrice, price, taxes } = fare
            Keys[fareKey] = { fareKey, fareClass, basePrice, price, taxes }
        }
    }
    flights.map((segment, index) => {
        let fare = segmentKeyPairs(segment.fares)
        fareBlock.push([fare])
    })

    if (isSplRt) {
        Keys.onward = { ...fareBlock[0] }
        Keys.return = { ...fareBlock[1] }
    }
    return Keys
}

const segmentKeyPairs = fares => {
    let faresArray = [...fares]
    let fare = []
    let temp = {}
    for (let i = 0; i < faresArray.length; i++) {
        if (faresArray[i].fareClass === 'RETAIL') {
            fare.push(faresArray[i])
        }
    }
    for (let i = 0; i < faresArray.length; i++) {
        if (faresArray[i].fareClass === 'SPECIAL_RT') {
            temp = { ...faresArray[i] }
        }
    }
    for (let i = 0; i < fare.length; i++) {
        if (fare[i].fareClass === 'RETAIL') {
            let diff = 0
            fare[i].splRtfareClass = temp.fareClass || fare[i].fareClass
            fare[i].splRtfareKey = temp.fareKey || fare[i].fareKey
            if (temp.price) {
                diff = fare[i].price - temp.price
            }
            fare[i].diff = diff
        }
    }
    return fare
}

export const getShortlistEntityPayload = (searchCriteria, date, flights, locale, currency, userId) => {
    let obj = {}
    let sc = {}
    let departDateString = date
    let sourceType = 'B2C'
    let cabinType = flights[0].segments[0].cabinType
    let sellingCountry = locale
    let sellingCurrency = currency
    const { from, to, international } = searchCriteria || {}
    const { adults, children, infants } = searchCriteria.paxInfo || {}

    sc = {
        from,
        to,
        international,
        adults,
        children,
        infants,
        departDateString,
        cabinType,
        sourceType,
        sellingCountry,
        sellingCurrency
    }
    obj = { sc: { ...sc }, userId }
    return obj
}

export const addShortlistPayload = (itineraryData, email) => {
    let obj = {}
    let sc = {}
    let metaData = {}
    let fd = []
    let flightKey = {}
    const { from, to, international, paxInfo, departDate, arrivalDate } = itineraryData.searchCriteria || {}
    const { adults, children, infants } = paxInfo || {}

    let cabinType = itineraryData.flights[0].segments[0].cabinType
    const departDateString = getFormattedDate(departDate, 'DD/MM/YYYY')
    const { locale, currency } = getCurrencyByLocale()
    let sellingCurrency = currency
    let sellingCountry = locale
    let sourceType = 'B2C'
    let returnDateString = arrivalDate ? getFormattedDate(arrivalDate, 'DD/MM/YYYY') : ''
    const { flights } = itineraryData
    let origin = flights[0].fromCity
    let destination = flights[0].toCity
    let domain = resolveKeysInObjectsArrays(['location', 'hostname'], window)
    let emailId = email
    let price = getStandardFare(flights)
    let fdObj = {}
    let returnDuration = flights[1] ? flights[1].totalDuration : null
    let onwardDuration = flights[0].totalDuration
    let onwardSegments = {}
    let onSegments = shortlistSegmentsObj(flights[0])
    let returnSegments = {}
    let reSegments = flights[1] ? shortlistSegmentsObj(flights[1]) : {}
    let onward = getFlightKey(flights[0])
    let retur = flights[1] ? getFlightKey(flights[1]) : ''
    sc = {
        from,
        to,
        international,
        adults,
        children,
        infants,
        departDateString,
        cabinType,
        sourceType,
        sellingCountry,
        sellingCurrency,
        returnDateString,
        origin,
        destination
    }

    metaData = {
        domain,

        price
    }
    if (!isEmpty(emailId) && emailId !== 'null') {
        metaData = {
            ...metaData,
            emailId
        }
    }
    onwardSegments = { ...onSegments }
    returnSegments = { ...reSegments }
    fdObj = {
        onwardDuration,
        onwardSegments,
        returnDuration,
        returnSegments
    }
    fd = [fdObj]
    flightKey = { onward }
    if (retur) {
        flightKey = { ...flightKey, return: retur }
    }
    obj = { sc: { ...sc }, metaData: { ...metaData }, fd: [...fd], flightKey: { ...flightKey } }
    return obj
}

const shortlistSegmentsObj = flights => {
    let segments = []
    let flight = flights.segments
    for (let segment of flight) {
        const {
            airline,
            airlineName,
            arrivalAirport,
            departAirport,
            flightNumber,
            duration,
            arrivalDateTime,
            departDateTime
        } = segment
        let arrivalDate = getFormattedDate(arrivalDateTime, 'DD/MM/YYYY')
        let departDate = getFormattedDate(departDateTime, 'DD/MM/YYYY')
        let arrivalTime = getFormattedDate(arrivalDateTime, 'HH:mm', 'YYYY-MM-DDTHH:mm:ssZ')
        let departTime = getFormattedDate(departDateTime, 'HH:mm', 'YYYY-MM-DDTHH:mm:ssZ')
        let obj = {
            airline,
            airlineName,
            arrivalAirport,
            departAirport,
            flightNumber,
            duration,
            arrivalDate,
            departDate,
            departTime,
            arrivalTime
        }
        segments.push(obj)
    }
    return segments
}

export const getFlightKey = flights => {
    let fares = flights.fares
    let segments = flights.segments
    let suppArr
    let supp
    let flightKey = ''
    for (let fare of fares) {
        if (fare.fareClass === 'RETAIL') {
            suppArr = fare.fareKey.split('|')
        }
    }
    for (let i = 0; i < suppArr.length; i++) {
        if (suppArr[i].search('supp') > -1) {
            supp = suppArr[i]
        }
    }
    supp = supp?.substring(5)
    for (let segment of segments) {
        const { airline, flightNumber, departDateTime, cabinType } = segment
        let departDate = getFormattedDate(departDateTime, 'DDMMYYYY')

        let departTime = getFormattedDate(departDateTime, 'HH:mm', 'YYYY-MM-DDTHH:mm:ssZ')
        flightKey += `|${supp}_${airline}-${flightNumber}_${departDate}_${departTime}_${cabinType.charAt(0)}`
    }
    flightKey = flightKey.substring(1)
    return flightKey
}

const getStandardFare = flights => {
    let price = 0
    for (let segment of flights) {
        for (let fare of segment.fares) {
            if (fare.fareClass === 'RETAIL') {
                price += fare.price
            }
        }
    }
    return parseInt(price)
}

export const countSegments = journey => {
    let count = 0
    count += (journey[0] && journey[0].segmentAncillaries.length) || 0
    count += (journey[1] && journey[1].segmentAncillaries.length) || 0
    return count
}

export const addOnsProps = (responseData, paxInfo, itineraryData) => {
    let addOnsProps = {}

    const segments = []
    const baggageSegments = []
    const mealErrorSegements = []
    const seatErrorSegements = []
    const flightSegments = []
    let isMealAvailable = false
    let isBaggageAvailable = false
    let isSeatAvailable = false
    const { flights = [] } = itineraryData || {}
    const paxCount = paxInfo.adults + paxInfo.children

    const journey =
        (responseData && responseData.ancillaryDetails && responseData.ancillaryDetails.journeyAncillaries) || []
    const onwardObj = journey[0] ? { ...journey[0] } : {}
    const returnObj = journey[1] ? { ...journey[1] } : {}
    let totalSegments = countSegments(journey)
    let showSinglePaxComponents = totalSegments <= 2 && paxCount === 1 ? true : false

    if (showSinglePaxComponents) {
        //in case of RT
        if (journey[1]) {
            journey[0] && journey[0].segmentAncillaries[0] && segments.push({ ...journey[0].segmentAncillaries[0] })
            flights[0] && flights[0].segments[0] && flightSegments.push(flights[0].segments[0])
            journey[1] && journey[1].segmentAncillaries[0] && segments.push({ ...journey[1].segmentAncillaries[0] })
            flights[1] && flights[1].segments[0] && flightSegments.push(flights[1].segments[0])
            journey[0] &&
                journey[0].baggageAncillary &&
                baggageSegments.push({ ...journey[0].baggageAncillary.baggageDetails[0] })
            journey[1] &&
                journey[1].baggageAncillary &&
                baggageSegments.push({ ...journey[1].baggageAncillary.baggageDetails[0] })
        } else if (journey[0]) {
            journey[0] && journey[0].segmentAncillaries[0] && segments.push({ ...journey[0].segmentAncillaries[0] })
            flights[0] && flights[0].segments[0] && flightSegments.push(flights[0].segments[0])
            journey[0] &&
                journey[0].baggageAncillary &&
                baggageSegments.push({ ...journey[0].baggageAncillary.baggageDetails[0] })
            journey[0] && journey[0].segmentAncillaries[1] && segments.push({ ...journey[0].segmentAncillaries[1] })
            flights[0] && flights[0].segments[1] && flightSegments.push(flights[0].segments[1])
            journey[0] &&
                journey[0].baggageAncillary &&
                journey[0].baggageAncillary.baggageDetails[1] &&
                baggageSegments.push({ ...journey[0].baggageAncillary.baggageDetails[1] })
        }
        isMealAvailable =
            segments[0] && segments[0].mealAncillaries && segments[0].mealAncillaries.mealInfos.length > 0
                ? true
                : false
        if (!isMealAvailable && segments[0]) {
            const to = segments[0].originDestinationInfo.to
            const from = segments[0].originDestinationInfo.from
            mealErrorSegements.push({
                key: `${from}_${to}`
            })
        }
        isBaggageAvailable = baggageSegments?.[0] && baggageSegments?.[0].baggageInfos?.length > 0 ? true : false
        isSeatAvailable =
            segments[0] &&
            segments[0].seatAncillaries &&
            (segments[0].seatAncillaries.aircraftLayout?.lowerDeck?.totalRows > 0 ||
                segments[0].seatAncillaries.aircraftLayout?.upperDeck?.totalRows > 0)
                ? true
                : false
        if (!isSeatAvailable && segments[0]) {
            const to = segments[0].originDestinationInfo.to
            const from = segments[0].originDestinationInfo.from
            seatErrorSegements.push({
                key: `${from}_${to}`
            })
        }
        if (isMealAvailable === false && segments[1]) {
            isMealAvailable =
                segments[1].mealAncillaries && segments[1].mealAncillaries.mealInfos.length > 0 ? true : false
            if (!isMealAvailable) {
                const to = segments[1].originDestinationInfo.to
                const from = segments[1].originDestinationInfo.from
                mealErrorSegements.push({
                    key: `${from}_${to}`
                })
            }
        }
        if (isBaggageAvailable === false && baggageSegments[1]) {
            isBaggageAvailable = baggageSegments[1].baggageInfos.length > 0 ? true : false
        }
        if (isSeatAvailable === false && segments[1]) {
            isSeatAvailable =
                segments[1] &&
                segments[1].seatAncillaries &&
                (segments[1].seatAncillaries.aircraftLayout?.lowerDeck?.totalRows > 0 ||
                    segments[1].seatAncillaries.aircraftLayout?.upperDeck?.totalRows > 0)
                    ? true
                    : false
            if (!isSeatAvailable) {
                const to = segments[1].originDestinationInfo.to
                const from = segments[1].originDestinationInfo.from
                seatErrorSegements.push({
                    key: `${from}_${to}`
                })
            }
        }
    } else {
        journey.forEach(trip => {
            !isEmpty(trip && trip.segmentAncillaries) &&
                trip.segmentAncillaries.forEach(segment => {
                    if (segment.mealAncillaries.mealInfos.length > 0) {
                    } else {
                        const from = segment.originDestinationInfo.from
                        const to = segment.originDestinationInfo.to
                        mealErrorSegements.push({
                            key: `${from}_${to}`
                        })
                    }
                    if (!isMealAvailable) {
                        isMealAvailable = segment.mealAncillaries.mealInfos.length > 0
                    }
                })
        })
        journey.forEach(trip => {
            !isEmpty(trip && trip.segmentAncillaries) &&
                trip.segmentAncillaries.forEach(segment => {
                    if (
                        segment?.seatAncillaries?.aircraftLayout?.lowerDeck?.totalRows > 0 ||
                        segment?.seatAncillaries?.aircraftLayout?.upperDeck?.totalRows > 0
                    ) {
                    } else {
                        const from = segment.originDestinationInfo.from
                        const to = segment.originDestinationInfo.to
                        seatErrorSegements.push({
                            key: `${from}_${to}`
                        })
                    }
                    if (!isSeatAvailable) {
                        isSeatAvailable =
                            (segment?.seatAncillaries?.aircraftLayout?.lowerDeck?.totalRows ||
                                segment?.seatAncillaries?.aircraftLayout?.upperDeck?.totalRows) > 0
                                ? true
                                : false
                    }
                })
        })
        journey.forEach(trip => {
            if (!isEmpty(trip && trip.baggageAncillary)) {
                trip.baggageAncillary.baggageDetails.forEach(segment => {
                    if (!isBaggageAvailable) {
                        isBaggageAvailable = segment.baggageInfos.length > 0
                    }
                })
            }
        })
    }
    let fareFamilyMealError = false
    let fareFamilySeatError = false
    // seat meal error case handle
    if (itineraryData.meta.ancillaryEnabled == true) {
        if (!isMealAvailable) {
            fareFamilyMealError = true
            isMealAvailable = true
        }
        if (!isSeatAvailable) {
            fareFamilySeatError = true
            isSeatAvailable = true
        }
    }
    addOnsProps = {
        ...addOnsProps,
        journey: journey,
        isMealAvailable: isMealAvailable,
        isBaggageAvailable: isBaggageAvailable,
        isSeatAvailable: isSeatAvailable,
        segments: segments,
        flightSegments: flightSegments,
        baggageSegments: baggageSegments,
        paxCount: paxCount,
        onwardObj: onwardObj,
        returnObj: returnObj,
        showSinglePaxComponents: showSinglePaxComponents,
        fareFamilyMealError,
        fareFamilySeatError,
        mealErrorSegements,
        seatErrorSegements
    }
    return addOnsProps
}

export const getFlightSegments = flights => {
    let segmentdata = []
    flights.map(flight => {
        let parentFrom = flight.from
        let parentTo = flight.to
        flight.segments.map(segment => {
            segmentdata.push({
                parent: `${parentFrom}_${parentTo}`,

                segment: `${segment.departAirport}_${segment.arrivalAirport}`
            })
        })
    })
    return segmentdata
}
export const makeDummyCorpObj = retailObj => {
    let obj = JSON.parse(JSON.stringify(retailObj))
    let corpDummy = obj.ancillaryDetails.journeyAncillaries.map(journey => {
        journey.baggageAncillary.baggageDetails.map(baggage => {
            baggage.baggageInfos = []
        })
        journey.segmentAncillaries.map(segment => {
            segment.mealAncillaries.mealInfos = []
            segment.seatAncillaries = {
                aircraftLayout: {
                    lowerDeck: {},
                    upperDeck: {}
                }
            }
        })
        return journey
    })
    let response = {
        fareSubType: 'CORP',
        ancillaryDetails: {
            journeyAncillaries: corpDummy
        }
    }
    return response
}

export const makeAncillaryResponse = (seatResponse, itineraryData) => {
    //written as backend couldn't make a proper response :)

    //return if ancillaryDetails [] or null
    if (itineraryData.itineraryStatus === 'FLIGHT_NOT_AVAILABLE') {
        return seatResponse
    }
    if (isEmpty(seatResponse)) {
        return seatResponse
    } else if (seatResponse && seatResponse[0].fareSubType === 'RETAIL' && isEmpty(seatResponse[0].ancillaryDetails)) {
        return seatResponse
    } else if (
        seatResponse[1] &&
        seatResponse[1].fareSubType === 'RETAIL' &&
        isEmpty(seatResponse[1].ancillaryDetails)
    ) {
        return seatResponse
    }
    let seatResponseArray = [...seatResponse]
    let journeySegments = []
    const isRt = itineraryData.flights.length > 1
    if (seatResponseArray.length > 1) {
        if (seatResponseArray[0].fareSubType === 'CORP') {
            ;[seatResponseArray[0], seatResponseArray[1]] = [seatResponseArray[1], seatResponseArray[0]]
        }
    }
    if (
        seatResponse &&
        seatResponse[0].fareSubType === 'RETAIL' &&
        seatResponse[0].ancillaryDetails &&
        isEmpty(seatResponse[0].ancillaryDetails.journeyAncillaries)
    ) {
        return seatResponse
    }

    journeySegments = itineraryData.flights.map(flight => {
        let obj = {
            from: flight.from,
            to: flight.to
        }
        return obj
    })
    const makeJourneyObj = (from, to) => {
        let dummyJourneyObj = {
            currency: 'INR',
            originDestinationInfo: {
                from: from,
                to: to
            },
            segmentAncillaries: [
                {
                    segmentIndex: 1,
                    originDestinationInfo: {
                        from: from,
                        to: to
                    },
                    mealAncillaries: {
                        mealInfos: [],
                        warnings: [],
                        errors: []
                    },
                    seatAncillaries: {
                        aircraftLayout: {
                            aircraftType: 'A320',
                            lowerDeck: {
                                deck: 'LOWER_DECK',
                                rows: []
                            },
                            upperDeck: null
                        },
                        warnings: [],
                        errors: []
                    }
                }
            ],
            baggageAncillary: {
                isSegmentLevelBaggage: false,
                baggageDetails: [
                    {
                        originDestinationInfo: {
                            from: from,
                            to: to
                        },
                        baggageInfos: []
                    }
                ]
            }
        }
        return dummyJourneyObj
    }

    if (isRt) {
        let sector = seatResponseArray[0].ancillaryDetails.journeyAncillaries[0].originDestinationInfo
        let sectorReturn =
            seatResponseArray[0].ancillaryDetails.journeyAncillaries.length > 1 &&
            seatResponseArray[0].ancillaryDetails.journeyAncillaries[1].originDestinationInfo
        if (sector.from !== journeySegments[0].from) {
            if (
                seatResponseArray[0].ancillaryDetails.journeyAncillaries.length > 1 &&
                sectorReturn.from !== journeySegments[1].from
            ) {
                ;[
                    seatResponseArray[0].ancillaryDetails.journeyAncillaries[0],
                    seatResponseArray[0].ancillaryDetails.journeyAncillaries[1]
                ] = [
                    seatResponseArray[0].ancillaryDetails.journeyAncillaries[1],
                    seatResponseArray[0].ancillaryDetails.journeyAncillaries[0]
                ]
            } else if (seatResponseArray[0].ancillaryDetails.journeyAncillaries.length === 1) {
                //make onward retail dummy journey
                let journeyObj = makeJourneyObj(journeySegments[0].from, journeySegments[0].to)
                seatResponseArray[0].ancillaryDetails.journeyAncillaries.unshift({
                    ...journeyObj
                })
            }
        } else {
            if (seatResponseArray[0].ancillaryDetails.journeyAncillaries.length === 1) {
                //make return retail journey

                let journeyObj = makeJourneyObj(journeySegments[1].from, journeySegments[1].to)

                seatResponseArray[0].ancillaryDetails.journeyAncillaries.push({
                    ...journeyObj
                })
            }
        }
    }
    if (isEmpty(seatResponseArray[0].ancillaryDetails.journeyAncillaries[0].segmentAncillaries)) {
        seatResponseArray[0].ancillaryDetails.journeyAncillaries[0].segmentAncillaries = [
            {
                segmentIndex: 1,
                originDestinationInfo: {
                    from: journeySegments[0].from,
                    to: journeySegments[0].to
                },
                mealAncillaries: {
                    mealInfos: [],
                    warnings: [],
                    errors: []
                },
                seatAncillaries: {
                    aircraftLayout: {
                        aircraftType: 'A320',
                        lowerDeck: {
                            deck: 'LOWER_DECK',
                            rows: []
                        },
                        upperDeck: null
                    },
                    warnings: [],
                    errors: []
                }
            }
        ]
    }
    //sort cfw situation here
    if (seatResponseArray[1] && !isEmpty(seatResponseArray[1].ancillaryDetails)) {
        if (isEmpty(seatResponseArray[1].ancillaryDetails.journeyAncillaries)) {
            seatResponseArray[1] = makeDummyCorpObj(seatResponseArray[0])
        }
        if (isRt) {
            let sector = seatResponseArray[1].ancillaryDetails.journeyAncillaries[0].originDestinationInfo
            let sectorReturn =
                seatResponseArray[1].ancillaryDetails.journeyAncillaries.length > 1 &&
                seatResponseArray[1].ancillaryDetails.journeyAncillaries[1].originDestinationInfo
            if (sector.from !== journeySegments[0].from) {
                if (
                    seatResponseArray[1].ancillaryDetails.journeyAncillaries.length > 1 &&
                    sectorReturn.from !== journeySegments[1].from
                ) {
                    ;[
                        seatResponseArray[1].ancillaryDetails.journeyAncillaries[0],
                        seatResponseArray[1].ancillaryDetails.journeyAncillaries[1]
                    ] = [
                        seatResponseArray[1].ancillaryDetails.journeyAncillaries[1],
                        seatResponseArray[1].ancillaryDetails.journeyAncillaries[0]
                    ]
                } else if (seatResponseArray[1].ancillaryDetails.journeyAncillaries.length === 1) {
                    //make onward corporate dummy journey
                    let journeyObj = makeJourneyObj(journeySegments[0].from, journeySegments[0].to)
                    seatResponseArray[1].ancillaryDetails.journeyAncillaries.unshift({
                        ...journeyObj
                    })
                }
            } else {
                if (seatResponseArray[1].ancillaryDetails.journeyAncillaries.length === 1) {
                    //make return corp journey

                    let journeyObj = makeJourneyObj(journeySegments[1].from, journeySegments[1].to)

                    seatResponseArray[1].ancillaryDetails.journeyAncillaries.push({
                        ...journeyObj
                    })
                }
            }
        }

        return seatResponseArray
    } else if (seatResponseArray[1] && isEmpty(seatResponseArray[1].ancillaryDetails)) {
        //check for empty or null response
        seatResponseArray[1] = makeDummyCorpObj(seatResponseArray[0])
    } else {
        //make cfw obj
        seatResponseArray.push(makeDummyCorpObj(seatResponseArray[0]))
    }
    return seatResponseArray
}

export const getSegmentBaggage = flightInfoResponse => {
    let obj = {}
    let baggageInfo = flightInfoResponse && flightInfoResponse.baggage && flightInfoResponse.baggage.BI
    if (baggageInfo) {
        Object.keys(baggageInfo).forEach(key => {
            let keyArray = key.split('_')
            let objKey = `${keyArray[0]}_${keyArray[1]}`
            let cib = baggageInfo[key].cib ? baggageInfo[key].cib : null
            obj[objKey] = cib
        })
    }
    return obj
}

export const ancillaryPrice = (code = '', price) => {
    if (code === 'KWD') {
        return price
    }
    return Math.ceil(price)
}

export const isAutoSelectProvider = provider => {
    return provider.includes('BRB')
}
export const isDomainForAutoSelectBRB = () => {
    const domain = resolveKeysInObjectsArrays(['location', 'hostname'], window)
    switch (domain) {
        case 'qa.cleartrip.com':
        case 'qa2.cleartrip.ae':
        case 'qa2.cleartrip.sa':
        case 'www.cleartrip.ae':
        case 'qa2.cleartrip.ae':
        case 'me.cleartrip.ae':
        case 'www.cleartrip.ae':
        case 'www.cleartrip.sa':
        case 'qa2.cleartrip.sa':
        case 'me.cleartrip.sa':
        case 'www.cleartrip.sa':
        case 'bh.cleartrip.com':
        case 'bh.cleartrip.sa':
        case 'www.cleartrip.bh':
        case 'www.cleartrip.bh':
        case 'om.cleartrip.com':
        case 'om.cleartrip.sa':
        case 'www.cleartrip.om':
        case 'www.cleartrip.om':
        case 'kw.cleartrip.com':
        case 'kw.cleartrip.sa':
        case 'www.cleartrip.com.kw':
        case 'www.cleartrip.kw':
        case 'qa.cleartrip.com':
        case 'qar.cleartrip.sa':
        case 'www.cleartrip.qa':
        case 'www.cleartrip.qa':
        case 'localhost':
            return true
        default:
            return false
    }
}
export const sortArrayOfObjects = (arr = [], key = '') => {
    let sortedArray = arr.sort((item1, item2) => {
        if (isNaN(item1[key])) {
            return 0
        }
        if (isNaN(item2[key])) {
            return -1
        }
        return item1[key] - item2[key]
    })
    return sortedArray
}
export const getCtFlexName = () => {
    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        return 'CT Flex'
    } else {
        return 'Flexifly'
    }
}
export const getEzcancelName = () => {
    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        return 'CT FlexMax'
    } else {
        return 'EzCancel'
    }
}
export const checkIsEzcancelPro = vas => {
    let ezcancelPro = false
    let isFlexifly = false
    let validDate = true
    if (isEmpty(vas)) {
        return false
    }
    // let d1 = new Date()
    // let d2 = new Date('2021-10-11')

    // if (d1 < d2) {
    //     validDate = true
    // }
    vas.forEach(vasItem => {
        if (vasItem.vasType === 'DOM_CANCELATION_INSURANCE' || vasItem.vasType === 'INTL_CANCELATION_INSURANCE') {
            ezcancelPro = true
        }
        if (vasItem.vasType === 'AMEND_INSURANCE' || vasItem.vasType === 'INTL_FLEXIFLY') {
            isFlexifly = true
        }
    })
    return ezcancelPro && !isFlexifly && validDate
}

export const getAddonsErrorMsg = (
    fareFamilyBenefits,
    addonsType,
    multipax,
    isCtUpgrade = false,
    isFromMealsTab = false
) => {
    let displayMessage = ''
    if (addonsType == 'SEAT') {
        displayMessage = 'Seat not available for this flight'
    }
    if (addonsType == 'MEAL') {
        if (isFromMealsTab) {
            displayMessage = 'Meals not available for this selection'
        } else {
            displayMessage = 'Meal not available for this flight'
        }
    }
    if (multipax) {
        fareFamilyBenefits?.forEach(benifit => {
            const { type, shortDesc } = benifit
            if (type === addonsType) {
                displayMessage = `${`you will be able to avail ${shortDesc.toLowerCase()} during web check-in`}`
            }
        })
    } else {
        fareFamilyBenefits?.forEach(benifit => {
            const { type, shortDesc } = benifit
            if (type === addonsType) {
                displayMessage = `${`We are facing some issues, you will be able to avail ${shortDesc.toLowerCase()} during web check-in`}`
            }
        })
    }
    if (isCtUpgrade) {
        if (addonsType == 'SEAT') {
            displayMessage = "We are facing some issues, We won't be able to provide free seat as part of {Icon}"
        }
        if (addonsType == 'MEAL') {
            displayMessage = "We are facing some issues, We won't be able to provide free meal as part of {Icon}"
        }
    }

    return displayMessage
}

export const getCtUpgradeDatafromAncillaries = data => {
    const finalData = []
    const { ancillaryDetails } = data[0]
    const { journeyAncillaries } = ancillaryDetails || {}
    if (isEmpty(journeyAncillaries)) return finalData
    journeyAncillaries?.forEach(journey => {
        let ctUpgrade = {}
        let benefitMessages = {}
        let applicableSegments = []
        let { benefitInfos, originDestinationInfo = {}, segmentAncillaries = [] } = journey || {}
        if (isEmpty(benefitInfos)) {
            benefitInfos = []
        }
        if (!isEmpty(originDestinationInfo)) {
            const key = originDestinationInfo.from + '_' + originDestinationInfo.to
            applicableSegments.push(key)
        }
        const ctUpgradeData = benefitInfos.find(benifit => benifit?.benefitType === benifits.CT_UPGRADE.text) || {}
        const mealBenifitsData =
            ctUpgradeData.benefits?.find(benefit => {
                return benefit?.benefitName === 'FREE_MEAL'
            }) || {}
        const seatBenifitsData =
            ctUpgradeData.benefits?.find(benefit => {
                return benefit?.benefitName === 'FREE_SEAT'
            }) || {}

        if (!isEmpty(seatBenifitsData.displayMessage)) {
            benefitMessages.seat = seatBenifitsData.displayMessage.replace('{BENEFIT_TYPE}', 'Cleartrip Upgrade')
        }
        if (!isEmpty(mealBenifitsData.displayMessage)) {
            benefitMessages.meal = mealBenifitsData.displayMessage.replace('{BENEFIT_TYPE}', 'Cleartrip Upgrade')
        }
        if (ctUpgradeData.displayMessage || !isEmpty(benefitMessages)) {
            segmentAncillaries.forEach(segment => {
                const { originDestinationInfo = {} } = segment
                if (!isEmpty(originDestinationInfo)) {
                    const key = originDestinationInfo.from + '_' + originDestinationInfo.to
                    applicableSegments.push(key)
                }
            })
            ctUpgrade = {
                displayMessage: ctUpgradeData.displayMessage,
                benefitMessages,
                applicableSegments
            }
        }
        finalData.push(ctUpgrade)
    })

    return finalData
}

export const getBenefitsByJourney = (index, ctUpgradeData, itinerarySourceData) => {
    const { itineraryData = [] } = ctUpgradeData || {}
    const { benefitMessages = {} } = itineraryData[index] || {}
    let isSeat = false
    let isMeal = false
    if (benefitMessages.seat) {
        isSeat = true
    }
    if (benefitMessages.meal) {
        isMeal = true
    }
    if (isSeat && isMeal) {
        return 'both seat & meal'
    } else if (isSeat) {
        return 'seat'
    } else if (isMeal) {
        return 'meal'
    } else {
        return ''
    }
}

export const getVasExp6Events = flights => {
    const onwardStops = flights[0]?.segments?.length - 1 || 0
    const returnStops = flights[1]?.segments?.length - 1 || 0
    return {
        return_stops_count: returnStops,
        onward_stops_count: onwardStops,
        onward_layover_flight: onwardStops ? 'yes' : 'no',
        return_layover_flight: returnStops ? 'yes' : 'no'
    }
}
export const getMaskedValue = binNumber => {
    if (isEmpty(binNumber)) return ''
    const value = String(binNumber)
    const mask = 'XXXX - XXXX'
    let i = 0
    let lastReplacedIndex = 0
    const maskedValue = mask.replace(/X/g, (_, j) => {
        if (i >= value.length) {
            return 'X'
        }
        lastReplacedIndex = j
        return value[i++]
    })
    return maskedValue.substring(0, lastReplacedIndex + 1)
}
