import {
    apiGetHelper,
    apiPostFormHelper,
    apiPostHelper,
    apiGetCurrency,
    apiGetParamsHelper,
    apiPutHelper,
    apiDeleteHelper,
    apiGetConfigHelper,
    apiPostConfigHelper
} from 'utils/apiHelper'
import { appPrefix, getCookie, isEmpty, setCookie } from 'utils/browserHelper'
import { DOMAIN, HOSTNAME, ANALYTICS_DOMAIN, CT_ENV, ORIGIN_DOMAIN } from 'constants/base'
import logError from 'utils/logError'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

export const flightResultsGetApi = queryParam => {
    //const URL = 'https://api.myjson.com/bins/rh8uc'
    // const URL = 'http://localhost:3000/twoway.json' //for ofline testing response data
    //const URL = 'http://localhost:3000/intlRT.json'
    //  const URL = 'https://api.myjson.com/bins/107oac'
    // const URL = 'https://api.myjson.com/bins/107oac'
    // const URL = 'https://api.myjson.com/bins/d79r8'
    // const URL = 'http://localhost:7000/'
    // const URL = `http://localhost:9000/node/flight/search?from=BLR&to=DXB&adults=1&childs=0&infants=0&class=Economy&airline=&carrier=&ver=V2&type=json&intl=y&search_ver=V2&depart_date=21/01/2020&return_date=26/01/2020`
    // const URL = `http://localhost:9000/node/flight/search?${queryParam}`
    // const URL = `http://localhost:9000/node/flight/search?from=BLR&to=BOM&depart_date=05/02/2020&adults=1&childs=0&infants=0&class=Economy&airline=&carrier=&intl=n&sd=1579492307926&page=loaded`

    // TODO: remove the commented out url
    // const URL =
    //     '/flight/search?trip_type=OneWay&from=DEL&to=VGA&depart_date=04/12/2019&adults=1&childs=0&infants=0&class=Economy&airline=&carrier=&ver=V2&type=json&intl=n&search_ver=V2'
    // const URL = '/book/flightsmisc/v2/getInsurance?itinerary-id=6854806d67-a7bb-40cc-97fc-191126151341'

    // const URL = `${DOMAIN}/flights/results/airjson?trip_type=OneWay&origin=Bangalore%2C+IN+-+Bengaluru+International+Airport+(BLR)&from=BLR&destination=Mumbai%2C+IN+-+Chatrapati+Shivaji+Airport+(BOM)&to=BOM&depart_date=24%2F12%2F2019&adults=1&childs=0&infants=0&class=Economy&airline=&carrier=&ver=V2&type=json&intl=n&sd=1574763412590&page=&search_ver=V2&cc=1&rhc=1&timeout=3000`
    //  const URL =     'http://localhost:3031/flights/results/airjson?trip_type=OneWay&origin=Bangalore%2C+IN+-+Bengaluru+International+Airport+(BLR)&from=BLR&destination=Mumbai%2C+IN+-+Chatrapati+Shivaji+Airport+(BOM)&to=BOM&depart_date=24%2F12%2F2019&adults=1&childs=0&infants=0&class=Economy&airline=&carrier=&ver=V2&type=json&intl=n&sd=1574763412590&page=&search_ver=V2&cc=1&rhc=1&timeout=3000'

    const URL = `${DOMAIN}/node/flight/search?${queryParam}`
    const response = apiGetHelper(URL)
    return response
}

export const travelSafeBannerStatusSRP = (oa, da, oc, dc) => {
    const URL = `${DOMAIN}/node/flight/travelsafe/route?oc=${oc}&dc=${dc}&oa=${oa}&da=${da}`
    const response = apiGetHelper(URL)
    return response
}

export const getSrpRtTupleCoupon = (payload) => {
    const URL = `${DOMAIN}/airservice/fetch-coupon-discount`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}
export const travelSafeData = url => {
    const URL = `${DOMAIN}${url}`
    const response = apiGetHelper(URL)
    return response
}

export const getEmiPrice = payload => {
    try {
        const URL = `${DOMAIN}/flights/emiOffers/eligibility`

        const response = apiPostHelper(URL, payload, 'application/json')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const getItinEmiPrice = payload => {
    try {
        const URL = `${DOMAIN}/itin/v2/itinerary/emiOffers/affordability`

        const response = apiPostHelper(URL, payload, 'application/json')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}
export const getSrpEmiPrice = payload => {
    try {
      const URL = `${DOMAIN}/flights/emiOffers/affordability`
  
      const response = apiPostHelper(URL, payload, 'application/json')
      return response
    } catch (e) {
      console.log(e)
      logError(e)
    }
  }

export const airportSearch = string => {
    let URL = `${DOMAIN}/places/airports/search?string=${string}`
    if (getCookie('lp') === 'ar' && appPrefix === '/ar') {
        URL = `${DOMAIN}/ar/suggest/airports?expectedNumberOfRecords=10&outputLanguages=ARABIC&inputText=${string}`
    }
    const response = apiGetHelper(URL)
    return response
}
export const airportSearchBluk = (payload) => {

    let URL = `${DOMAIN}/places/airports/search/v3`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}
export const airLineSearch = string => {
    let URL = `${DOMAIN}/flights/airlines/search?string=${string}`
    const response = apiGetHelper(URL)
    return response
}

export const getCurrenciesApi = () => {
    const timestamp = new Date()
        .toISOString()
        .split('T')[0]
        .split('-')
        .join('') //YYYYMMDD
    const URL = `${process.env.CT_PUBLIC_CURRENCY_ENDPOINT}?ts=${timestamp}`

    const response = apiGetCurrency(URL)
    return response
}

export const fetchPTBgetApi = queryString => {
    const URL = `${DOMAIN}${appPrefix}/ptb/coupons?${queryString}`
    const response = apiGetHelper(URL, 'application/json; charset=utf-8')
    return response
}

export const userStateApi = userId => {
    const URL = `${DOMAIN}${appPrefix}/workplus/user/state?userId=${userId}`
    const response = apiGetHelper(URL)
    return response
}

export const flightInitiateBookingPostApi = payload => {
    const URL = `${DOMAIN}${appPrefix}/flights/initiate-booking`
    const response = apiPostFormHelper(URL, payload)
    return response
}

export const flightNewInitiateBookingPostApi = payload => {
    let URL = `${DOMAIN}/itin/itinerary/create`
    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        URL = `${DOMAIN}/itin/v3/itinerary/create`
    }
    const { itineraryMeta: { dcFlow } = {} } = payload || {}
    let headers = {
       'dc-flow': dcFlow ? 'ENABLED' : 'DISABLED'
    }
    const response = apiPostHelper(URL, payload, 'application/json',headers)
    return response
}

export const fetchAncillariesData = itinId => {
    const URL = `${DOMAIN}/itin/v2/${itinId}/ancillaries/fetch?includeBaggage=true&includeMeal=true&includeSeat=true`
    const response = apiGetHelper(URL)
    return response
}

export const putAncillariesData = (itinId, payload) => {
    const URL = `${DOMAIN}/itin/v1/${itinId}/ancillaries/update`
    const response = apiPutHelper(URL, payload, 'application/json')
    return response
}

export const deleteAncillariesData = itinId => {
    const URL = `${DOMAIN}/itin/v1/${itinId}/ancillaries/remove-all`
    const response = apiDeleteHelper(URL)
    return response
}

export const flightFreeBaggagePostApi = payload => {
    const URL = `${DOMAIN}${appPrefix}/flights/freeBaggageInfo`
    const response = apiPostHelper(URL, payload)
    return response
}

export const flightAddShortlistPostApi = payload => {
    //willBreak
    const URL = `${DOMAIN}${appPrefix}/flight/shortlist/add`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const flightRemoveShortlistPostApi = payload => {
    const URL = `${DOMAIN}${appPrefix}/flight/shortlist/delete`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const fetchShortListPostApi = payload => {
    const URL = `${DOMAIN}${appPrefix}/flight/shortlist/fetch/entity`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

//TODO: need to chnage the domain - used for local purpose
export const flightFareMiniRulePostApi = payload => {
    // const URL = 'http://localhost:3000/miniResponse.json'
    //const response = apiGetHelper(URL)
    // TODO Abhishek - Make the domain= dynamic
    const URL = `${DOMAIN}${appPrefix}/flights/getminirule?domain=${HOSTNAME}`
    const response = apiPostHelper(URL, payload)
    return response
}

export const flightCorpFareMiniRulePostApi = payload => {
    const URL = `${DOMAIN}/flights/getminirule?domain=${HOSTNAME}&corp=true`
    const response = apiPostHelper(URL, payload)
    return response
}

export async function getDateTabFares(requestParams) {
    try {
        const URL = `${DOMAIN}/flights/flexisearchV2`
        const PAYLOAD = requestParams
        const response = await apiPostHelper(URL, PAYLOAD, 'application/json')
        return response
    } catch (e) {
        logError(e)
        return e
    }
}

export const getAbTestConfig = queryParam => {
    if (CT_ENV !== 'development') {
        const URL = `${DOMAIN}/source/abtest/getabtestconfig`
        const response = apiGetHelper(URL)
        return response
    }
}

export const fetchTransitVisaPostApi = payload => {
    const URL = `${DOMAIN}${appPrefix}/flights/transitvisa`
    const response = apiPostHelper(URL, payload, 'application/json; charset=UTF-8')
    return response
}
export const updateInsuranceDetailsApi = (itineraryId, payload) => {
    const URL = `${DOMAIN}/itin/${itineraryId}/insurance/quotation`

    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}
export const fetchItineary = itineraryId => {
    //oneway const URL = `https://api.myjson.com/bins/x6sso`
    //const URL = `https://api.myjson.com/bins/16d4rs`
    //const URL = `https://api.myjson.com/bins/vi7gw`
    //const URL = 'http://localhost:3000/twoway.json'
    let URL = `${DOMAIN}/itin/itinerary/${itineraryId}`
    if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        URL = `${DOMAIN}/itin/v3/itinerary/${itineraryId}`
    }
    let browserId = localStorage.getItem("browserId")
    let tabId = sessionStorage.getItem("tabId")
    if (isEmpty(browserId)) {
        const newBrowserId = uuidv4()
        browserId = newBrowserId
        localStorage.setItem("browserId", newBrowserId)
    }
    if (isEmpty(tabId)) {
        const newTabId = uuidv4()
        tabId = newTabId
        sessionStorage.setItem("tabId", newTabId)
    }
    const response = apiGetHelper(URL, '', { browserId, tabId })
    //const response = apiPostHelper(URL, payload, 'application/json; charset=UTF-8')
    return response
}
export const itineraryUpdateApi = payload => {
    const URL = `${DOMAIN}/itin/itinerary/update`

    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}
export const itineraryCashBackApi = (payload, userId) => {
    const URL = `${DOMAIN}${appPrefix}/workplus/cashback?userId=${userId}`

    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const validatePaybackUser = payload => {
    let URL = `${DOMAIN}/itin/${payload.itineraryId}/payback/checkAccount`
    const response = apiGetParamsHelper(URL, payload.queryParams, 'application/json')
    return response
}

export const sendPinNotification = (itineraryId, payload) => {
    let URL = `${DOMAIN}/itin/${itineraryId}/payback/forgotPassword`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const getBalance = (itineraryId, payload) => {
    let URL = `${DOMAIN}/itin/${itineraryId}/payback/getBalance`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const sendOtp = async ({ itineraryId, mobileNumber }) => {
    const URL = `${DOMAIN}/itin/${itineraryId}/supercoins/send-otp?mobileNumber=+91${mobileNumber}`
    const response = apiGetConfigHelper(URL, null, null, { validateStatus: () => true })
    return response

    // return {
    //     data: {
    //         responseCode: 200,
    //         error: null,
    //         success: true,
    //         data: {
    //             trackId: 'NI683e4ed222-65f0-4016-9561-210830134149',
    //             status: 'S',
    //             description: 'OTP sent successfully',
    //             isLastOtpRetry: i < 0
    //         }
    //     }
    // }
}

export const sendOtpForSignIn = async payload => {
    const URL = `${DOMAIN}/accounts/external-api/otp`
    // console.log(payload)
    // return {
    //     status: 200,
    //     data: {
    //         comm_id: '200',
    //         status: '200',
    //         action: 'SIGN_UP'
    //     }
    // }
    const customHeader = payload?.type === 'MOBILE' ? {
        caller: `${DOMAIN}`,
        'ab-otp': 'b'
    } : {
        caller: `${DOMAIN}`
    }
    const response = apiPostHelper(URL, payload, 'application/json', customHeader)
    return response
}
export const verifyOtpForSignIn = async (payload, customHeader = {}) => {
    const URL = `${DOMAIN}/accounts/external-api/otp/verify-action`
    // console.log(payload)
    // return {
    //     status: 200,
    //     data: {
    //         action: 'SIGNUP'
    //     }
    // }
    const headers = payload?.type === 'MOBILE' ? {
        caller: `${DOMAIN}`,
        'ab-otp': 'b',
        ...customHeader
    } : {
        caller: `${DOMAIN}`,
        ...customHeader
    }
    const response = apiPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const checkForLinkingNudge = async payload => {
    const URL = `${DOMAIN}/accounts/external-api/otp/verify-action`
    console.log(payload)
    // return {
    //     status: 200,
    //     data: {
    //         action: true
    //     }
    // }
    const response = apiPostHelper(URL, payload, 'application/json', { caller: `${DOMAIN}` })
    return response
}
export const getLinkedEmailsList = async ({ mobileNumber, countryCode }) => {
    const URL = `${DOMAIN}/accounts/external-api/users`
    // console.log(URL)
    // return {
    //     status: 200,
    //     data: {
    //         users: [
    //             {
    //                 id: '123',
    //                 username: 'ashok.chitkara@chitkara.com'
    //             },
    //             {
    //                 id: '12',
    //                 username: 'a@a.com'
    //             },
    //             {
    //                 id: '124',
    //                 username: 'random@api.com'
    //             },
    //             {
    //                 id: '123',
    //                 username: 'as123@123cc.com'
    //             }
    //         ]
    //     }
    // }
    // return {
    //     status: 200,
    //     data: {
    //         users: []
    //     }
    // }
    const response = apiGetHelper(URL, {}, { caller: `${DOMAIN}` })
    return response
}
export const getUserProfileDetails = () => {
    let URL = `${ORIGIN_DOMAIN}/accounts-api/people/v2/fetch/details`
    if (process.env.CT_ENV !== 'production') {
        URL = `${ORIGIN_DOMAIN}/people/v2/fetch/details`
    }

    const response = apiGetHelper(URL, {}, { caller: `${DOMAIN}` })
    return response
}

export const submitPersonalDetails = async payload => {
    let URL = `${DOMAIN}/accounts/external-api/user`
    console.log(payload)

    const response = apiPutHelper(URL, payload, 'application/json', { caller: `${DOMAIN}` })
    return response
}

export const linkAccounts = async payload => {
    let URL = `${DOMAIN}/external-api/user`
    console.log(payload)
    return {
        status: 200
    }

    // const response = apiPutHelper(URL, payload, 'application/json')
    // return response
}

export const saveNudge = async type => {
    let URL = `${DOMAIN}/accounts/external-api/nudge?type=${type}`
    // console.log(payload)
    // return {
    //     status: 200
    // }

    const response = apiPostHelper(URL, null, 'application/json', { caller: `${DOMAIN}` })
    return response
}

export const validateOtp = async ({ itineraryId, mobileNumber, otp }) => {
    let URL = `${DOMAIN}/itin/${itineraryId}/supercoins/validate-otp?mobileNumber=+91${mobileNumber}`
    const payload = {
        otp: `${otp}`,
        mobileNumber: `${'+91' + mobileNumber}`
    }
    const response = apiPostConfigHelper(URL, payload, 'application/json', null, { validateStatus: () => true })
    return response
    // return {
    //     data: {
    //         responseCode: 200,
    //         error: null,
    //         success: true,
    //         data: {
    //             trackId: 'NI683e4ed222-65f0-4016-9561-210830134149',
    //             status: 'S',
    //             description: 'Otp validated successfully',
    //             pointBalance: 3021,
    //             amount: 604,
    //             redeemedTouchPoints: 604
    //         }
    //     }
    // }
}
export const applyDiscount = ({ itineraryId, payload }) => {
    const URL = `${DOMAIN}/itin/${itineraryId}/apply-discount`
    const response = apiPutHelper(URL, payload, 'application/json', { 'X-CT-KEY': true })
    return response
}

export const removeDiscount = ({ itineraryId, payload, revalidate = false }) => {
    const URL = `${DOMAIN}/itin/${itineraryId}/remove-discount`
    const headers = revalidate ? { 'X-CT-KEY': true, 'X-REMOVE': false } : { 'X-CT-KEY': true }
    const response = apiPutHelper(URL, payload, 'application/json', headers)
    return response
}

export const removeGv = ({ itineraryId, payload }) => {
    const URL = `${DOMAIN}/itin/itinerary/${itineraryId}/remove-voucher`
    const response = apiPutHelper(URL, payload, 'application/json')
    return response
}

export const getEarnConfig = itineraryId => {
    const URL = `${DOMAIN}/itin/${itineraryId}/supercoins/earn-config`
    const response = apiGetHelper(URL)
    return response
}

export const itineraryPrePaymentApi = payload => {
    const URL = `${DOMAIN}/itin/itinerary/${payload.itineraryId}/prepayment`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}
export const itineraryConfirmApi = itineraryId => {
    const URL = `${DOMAIN}/itin/itinerary/${itineraryId}/appConfirm`
    const response = apiGetHelper(URL)
    return response
}

export const crossSellApi = () => {
    const url = `${DOMAIN}/offermgmt/cross-sell/B2C_desktop_in.js`
    const response = apiGetHelper(url)
    return response
}

export const flightInfoApi = itineraryId => {
    const URL = `${DOMAIN}/itin/api/itinerary/${itineraryId}/info/flightInfo`
    const response = apiGetHelper(URL)
    return response
}

export const screenshotStep = payload => {
    const URL = `${DOMAIN}/sc_external_api/initiate`
    const response = apiPostHelper(URL, payload)
    return response
}

export const screenshotConfirm = payload => {
    const URL = `${DOMAIN}/sc_external_api/reconfirm`
    const response = apiPostHelper(URL, payload)
    return response
}

export const flightMiniRuleApi = itineraryId => {
    const URL = `${DOMAIN}/itin/api/itinerary/${itineraryId}/info/minirules`
    const response = apiGetHelper(URL)
    return response
}
export const getFlightOffer = code => {
    const URL = `https://ui.cltp.co/offer-banner/settings/${code}_banner.json?v=${Math.floor(
        +new Date() / 3e3
    )}`
    const response = apiGetHelper(URL)
    return response
}
export const getcarausalOffer = itineraryId => {
    let URL = `https://www.cleartrip.sa/offermgmt/staging/B2CAir_sa.js?v1451305772`
    if (process.env.CT_ENV == 'production') {
        URL = `https://www.cleartrip.sa/offermgmt/B2CAir_sa.js?v1451305772`
    }

    const response = apiGetHelper(URL)
    return response
}

export const userSignIn = payload => {
    // const URL = `${DOMAIN}${appPrefix}/itinerary/${itineraryData.itineraryId}/user`
    // const URL = '//identity-microservice.cltp.com:9001/signin'
    const URL = `${DOMAIN}/v2/signin`
    // const URL = `${DOMAIN}${appPrefix}/itin/user/signin`
    const requestHeaders = {
        'X-CT-SOURCETYPE': 'web',
        service: window.location.pathname
    }
    const response = apiPostHelper(URL, payload, 'application/json', requestHeaders)
    return response
}

export const userSignUp = payload => {
    let URL = `${DOMAIN}/accounts-api/user/v2/register`
    if (process.env.CT_ENV !== 'production') {
        URL = `${DOMAIN}/user/v2/register`
    }
    const requestHeaders = {
        'x-ct-caller-app': 'web'
    }
    const response = apiPostHelper(URL, payload, 'application/json', requestHeaders)
    return response
}

export const userResetPassword = email => {
    let URL = `${ORIGIN_DOMAIN}/accounts-api/v2/user/resetPassword?email=${email}`
    if (process.env.CT_ENV !== 'production') {
        URL = `${ORIGIN_DOMAIN}/v2/user/resetPassword?email=${email}`
    }
    const response = apiPostHelper(URL)
    return response
}

export const getUserByEmailId = email => {
    let URL = `${ORIGIN_DOMAIN}/accounts-api/people/v2/fetch?domain=${ORIGIN_DOMAIN}&emailId=${email}`
    if (process.env.CT_ENV !== 'production') {
        URL = `${ORIGIN_DOMAIN}/people/v2/fetch?domain=${ORIGIN_DOMAIN}&emailId=${email}`
    }

    const response = apiGetHelper(URL, {})
    return response
}

export const getUserByMobile = (mobile, countryCode) => {
    let code = encodeURIComponent(countryCode)
    let URL = `${ORIGIN_DOMAIN}/accounts-api/people/v2/fetch?domain=${ORIGIN_DOMAIN}&mobile=${mobile}&countryCode=${code}`
    if (process.env.CT_ENV !== 'production') {
        URL = `${ORIGIN_DOMAIN}/people/v2/fetch?domain=${ORIGIN_DOMAIN}&mobile=${mobile}&countryCode=${code}`
    }

    const response = apiGetHelper(URL, {})
    return response
}

export const facebookLoginApi = () => {
    const URL = `${ORIGIN_DOMAIN}/account/singly/singly_api?source=web&service=https://cleartrip.sa`
    // ${DOMAIN}`
    const response = apiGetHelper(URL)
    return response
}

export const applyCoupon = (payload, itineraryId) => {
    const URL = `${DOMAIN}/itin/itinerary/${itineraryId}/applyCoupon`
    //const URL = 'http://localhost:3000/applyCoupon_error.json'
    //const URL = 'http://localhost:3000/applyCoupon_Sucess.json'
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const applyVoucher = (payload, itineraryId) => {
    const URL = `${DOMAIN}/itin/itinerary/${itineraryId}/applyVoucher`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const applyShukran = payload => {
    const URL = `${DOMAIN}/freqFlyerService/v1/fareKeys/loyaltyRewardsPoint`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const createShukranPayload = fares => {
    // const baseFare = pricings.adtt + pricings.chdt + pricings.inftt
    const shukranPayload = {
        fares: [
            {
                fare_key: fares.fareKey,
                price: fares.price,
                fare_breakup: {
                    BF: fares.basePrice,
                    TAX: fares.taxes
                }
            }
        ],
        program_name: 'SHUKRAN'
    }
    return shukranPayload
}
export const setFareAlert = (payload, filterDataContext, currencyContext, searchQueryContext) => {
    const Url = `${DOMAIN}/farealert/create`
    const { email, phoneNo, firstName = '', lastName = '' } = payload
    const lowestPrice = filterDataContext.oneWayPrice.min
    const currency = currencyContext.conversionRate.code

    const preferredDepTimeMap = {
        '0000': '0_8',
        '0800': '8_12',
        '1200': '12_16',
        '1600': '16_20',
        '2000': '20_24'
    }
    let preferredDepTime = filterDataContext.departureTimeFrom[0].selectedValues.map(
        timeId => preferredDepTimeMap[timeId]
    )

    const { from, to, adults, childs, infants, depart_date, return_date } = searchQueryContext
    let journeyType = 'OW'
    let departDate = [depart_date]
    if (return_date) {
        departDate.push(return_date)
        journeyType = 'RT'
    }
    let fromArr = [],
        toArr = []
    fromArr.push(from)
    toArr.push(to)
    if (journeyType === 'RT') {
        fromArr.push(to)
        toArr.push(from)
    }

    const reqObj = {
        alert: {
            email,
            phoneNo,
            channel: 'B2C',
            domain: DOMAIN,
            price: lowestPrice,
            preferredDepTime,
            currency: currency,
            firstName: firstName,
            lastName: lastName,
            clevertapID: process.env.CT_PUBLIC_CLEVERTAP_KEY || ''
        },
        search: {
            from: fromArr,
            to: toArr,
            departDate,
            journeyType,
            adults,
            children: childs,
            infants,
            flyingClass: searchQueryContext.class,
            // TODO Anoop "flyingClass": "E",
            country: DOMAIN_COUNTRY_MAP[DOMAIN] || ''
        }
    }

    return axios.post(Url, reqObj, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8, text/html'
        }
    })
}

export const getProfileDetails = () => {
    // const Url = 'http://localhost:7000/profile'
    const Url = `${DOMAIN}/partial/account/profile`
    return axios.get(Url, {
        headers: {
            Accept: 'text/json'
            // Accept: 'application/json',
            // 'Content-Type': 'application/json; charset=UTF-8, text/html'
        }
        // withCredentials: true
    })
}

/*

/workplus/user/update

req = {
  "id": "103794162",
  "userState": "TRIAL",
  "gstin": "22AAAAA0000A1Z5",
  "emailID": "anoop.raveendran@cleartrip.com",
  "gst_holder_name": ""
}

*/

export const updateGstNumberForCfw = (id, emailID, gstin, gst_holder_name) => {
    const Url = `${DOMAIN}/workplus/user/update`
    // return new Promise((resolve, reject) => {
    //     console.log('update GST - ', id, emailID, gstin, gst_holder_name)
    //     setTimeout(() => {
    //         reject()
    //     }, 3000)
    // })
    return axios.put(
        Url,
        {
            id,
            emailID,
            gstin,
            userState: 'TRIAL',
            gst_holder_name
        },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }
    )
}
export const getItineraryOffer = async (url, isMetaSource = false) => {
    let path = 'prod'
    if (process.env.CT_ENV !== 'production') {
        path = 'staging'
    }
    let offerPath = isMetaSource ? "itin_meta" : "srp_itin"

    const URL = `${DOMAIN}/offermgmt/fkMarketing/${path}/flights_${offerPath}_desktop_${url}.json?${new Date().getTime()}`
    const response = await apiGetHelper(URL)

    return response
}

export const TripSuggestorApi = (...args) => {
    let url = `${DOMAIN}/offermgmt/popular_destinations/index.json?trips=`
    const ARGS = [...args].join(',')
    const response = apiGetHelper(url + ARGS)
    return response
}

export const FetchFareFamilyInfo = payload => {
    let URL = `${DOMAIN}/aircon-api/fare-family/benefits`
    const response = apiPostHelper(URL, payload, 'application/json')
    return response
}

export const getFareFamily = (itineraryId, type = '') => {
    let URL = `${DOMAIN}/itin/${itineraryId}/fare-family-info`
    if (type) {
        URL = `${DOMAIN}/itin/${itineraryId}/fare-family-info?insuranceType=${type}`
    }
    const response = apiGetHelper(URL)
    return response
}

export const travelsafeNudgeAPI = (source, destination) => {
    const URL = `${DOMAIN}/node/flight/travelsafe/mini-guide?oa=${source}&da=${destination}`
    const response = apiGetHelper(URL)
    // const data = {"data":["Vaccination Proof","Home Quarantine"],"link":"https://www.cleartrip.com/travelsafe?from=JAI&to=DEL&"}
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(data)
    //   }, 500)
    // })
    return response
}

export const verifyHiFiveRefLink = refLink => {
    try {
        const isProd = process.env.NODE_ENV === 'production'
        const URL = `${DOMAIN}/referral/validate?referralLink=https://${isProd ? 'm' : 'qa2m'
            }.cltp.in/ref/${refLink}&action=HI_FIVE`
        const response = apiGetHelper(URL, {}, { caller: `${DOMAIN}` })
        return response
    } catch (e) {
        console.error(e)
    }
}

export const getHiFiveConfig = () => {
    try {
        const URL = `${DOMAIN}/referral/configDetails?action=HI_FIVE`
        const header = {
            caller: `${DOMAIN}`
        }
        const response = apiGetHelper(URL, {}, header)
        return response
    } catch (e) {
        console.error(e)
    }
}

export const getTopHotels = payload => {
    const { chkIn, chkOut, pax, city_name, airport_code } = payload
    let URL = `${DOMAIN}/hotels/crosssell/tophotels?checkIn=${chkIn}&checkOut=${chkOut}&pax=${pax}&city_name=${city_name}&airport_code=${airport_code}`
    const response = apiGetHelper(URL, '', {})

    return response
}

export const fetchSuperCoins = itineraryId => {
    const URL = `${DOMAIN}/itin/v2/${itineraryId}/reward/fetchBalance?rewardsType=SUPERCOINS`
    const response = apiGetHelper(URL, '', {})
    return response
}

export const applyBenefits = (payload) => {
    const URL = `${DOMAIN}/itin/itinerary/applyBenefits`
    const response = apiPostHelper(URL,payload, 'application/json')
    return response
}
export const personalization = (localStorageDeviceId) => {
    try {
      const URL = `${DOMAIN}/personalization` // add domain and same in proxy file
      const header = {
        caller: DOMAIN,
      ...(localStorageDeviceId && {dvid:localStorageDeviceId})
      }
      const response = apiGetHelper(URL,'', header)
      return response
    } catch (e) {
      console.error(e)
    }
}

export const getCompliancePolicyStatus = async () => {
    try {
        const URL = `${DOMAIN}/compliance`
        const header = {
            caller: 'UI',
        }
        const response = await apiGetHelper(URL, {}, header)
        if (response.status === 200) {
            return response.data
        }
        return Promise.reject()    
    } catch (e) {
        console.error(e)
    }
}

export const setCompliancePolicyStatus = (payload) => {
    try {
        const URL = `${DOMAIN}/compliance`
        const header = {
            caller: 'UI',
        }
        return apiPostHelper(URL, payload, 'application/json', header)
    }
    catch(e) {
        console.error(e)
    }
}
