import { resolveKeysInObjectsArrays, isEmpty } from './browserHelper'

//encoding the utm_currency cookie value
export const encodingValue = (obj = '') => {
    const value = obj.split('')
    let string = ''
    const len = value.length
    for (let i = 0; i < len; i++) {
        if (i < len - 1) {
            string = string + value[i].charCodeAt() + '-'
        } else {
            string = string + value[i].charCodeAt()
        }
    }
    return string
}

//decoding the utm_currency cookie value
export const decodingValue = obj => {
    const value = obj.split('|')
    const currSymbol = (value[1] && value[1].split('-')) || ''
    let string = ''
    for (let i = 0; i < currSymbol.length; i++) {
        string = string + String.fromCharCode(currSymbol[i])
    }
    string = value[0] + '|' + string
    return string
}

const currencyPrecisionMap = {
    KWD: 2,
    USD: 2,
    INR: 0,
    AED: 0,
    SAR: 0
}
export const getCurrencyFraction = currency => {
    const precision = currencyPrecisionMap[currency] || 0 //Default precision is 0
    switch (precision) {
        case 0:
            return { minimumFractionDigits: 0 }
        default:
            return {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
                enabled: true
            }
    }
}
export const getCurrencyByLocale = () => {
    const domain = resolveKeysInObjectsArrays(['location', 'hostname'], window) // window.location.hostname;
    switch (domain) {
        case 'www.cleartrip.ae':
        case 'qa2.cleartrip.ae':
        case 'me.cleartrip.ae':
        case 'www.cleartrip.ae':
        case 'localhost':
            return { locale: 'AE', currency: 'AED', country: 'UAE', symbol: 'AED' }
        case 'bh.cleartrip.com':
        case 'qa2bh.cleartrip.com':
        case 'bh.cleartrip.sa':
        case 'www.cleartrip.bh':
        case 'www.cleartrip.bh':
            return { locale: 'BH', currency: 'BHD', country: 'BHR', symbol: 'BHD' }
        case 'om.cleartrip.com':
        case 'qa2om.cleartrip.com':
        case 'om.cleartrip.sa':
        case 'www.cleartrip.om':
        case 'www.cleartrip.om':
            return { locale: 'OM', currency: 'OMR', country: 'OMN', symbol: 'OMR' }
        case 'kw.cleartrip.com':
        case 'qa2kw.cleartrip.com':
        case 'kw.cleartrip.sa':
        case 'www.cleartrip.com.kw':
        case 'www.cleartrip.kw':
            return { locale: 'KW', currency: 'KWD', country: 'KWT', symbol: 'KWD' }
        case 'qa.cleartrip.com':
        case 'qa2qa.cleartrip.com':
        case 'qar.cleartrip.sa':
        case 'www.cleartrip.qa':
        case 'www.cleartrip.qa':
            return { locale: 'QA', currency: 'QAR', country: 'QAT', symbol: 'QAR' }
        case 'www.cleartrip.sa':
        case 'qa2.cleartrip.sa':
        case 'me.cleartrip.sa':
        case 'www.cleartrip.sa':
            return { locale: 'SA', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
        case 'qa2.cleartrip.com':
            return { locale: 'IN', currency: 'INR', country: 'IN', symbol: '₹' }
        case 'me.cleartrip.com':
        case 'qa2me.cleartrip.com':
            return { locale: 'ME', currency: 'USD', country: 'ME', symbol: '$' }
        case 'www.cleartrip.me':
            return { locale: 'ME', currency: 'USD', country: 'ME', symbol: '$' }
        case 'www.cleartrip.eu':
            return { locale: 'EU', currency: 'EUR', country: 'EUR', symbol: '€' }
        case 'www.cleartrip.pl':
            return { locale: 'EU', currency: 'EUR', country: 'EUR', symbol: '€' }
        case 'www.cleartrip.co.za':
            return { locale: 'ZA', currency: 'ZAR', country: 'ZA', symbol: 'R' }
        default:
            return { locale: 'SA', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
    }
}

// todo - Sankalp, should delete not used
// export const getCurrency = (currency = 'INR') => {
//     const langMeta = getLanguageMeta()
//     currency = langMeta.currency
//     return currency
// }

export const getCurrencySymbol = (currencyCode = '', currencyList = {}) => {
    const { top = [], all = [] } = currencyList
    let currencySymbol
    for (let i = 0; i < top.length; i++) {
        if (top[i].code === currencyCode) {
            currencySymbol = top[i].symbol
            break
        }
    }
    if (isEmpty(currencySymbol)) {
        for (let i = 0; i < all.length; i++) {
            if (all[i].code === currencyCode) {
                currencySymbol = all[i].symbol
                break
            }
        }
    }
    return currencySymbol
}

//todo- Sankalp should delete not used
// export const getCurrencyandSymbol = () => {
//     const langMeta = getLanguageMeta()
//     const { currency = 'INR', symbol = '₹' } = langMeta
//     return currency + '|' + symbol
// }

export const getLocaleByCurrency = (currency = 'SAR') => {
    const locales = {
        AED: 'en-AE',
        OMR: 'en-OM',
        KWD: 'en-KW',
        BHD: 'en-BH',
        SAR: 'en-SA',
        USD: 'en-US',
        QAR: 'en-QA'
    }
    return locales[currency]
}
export const formatNumber = (number) =>{
    let formatedNumber = ''
    if(!isEmpty(number)){
        formatedNumber = number.toLocaleString('en-IN')
    }
    
    return formatedNumber
}
export const formatCurrency = ({ price, rate, currencyCode, sellingRate, countUp, spaceBetween }) => {
    const locale = getLocaleByCurrency(currencyCode)
    const fraction = getCurrencyFraction(currencyCode) //TO-DO currencyPrecisionMap

    let formatted = 'N/A'
    let value = ''
    if (price === 'null') {
        return formatted
    }
    if (!isEmpty(currencyCode) && !isEmpty(price)) {
        const convertedPrice = (Number(sellingRate) / Number(rate)) * parseFloat(price.toString(), 10)
        if (countUp) {
            // formatted = convertedPrice

            formatted = parseFloat(convertedPrice, 10).toLocaleString(locale, {
                currency: currencyCode,
                ...fraction
            })
            formatted = parseFloat(formatted.replace(/,/g, ''), 10)

            value = parseFloat(convertedPrice, 10).toLocaleString({
                currency: currencyCode,
                ...fraction
            })
            value = parseFloat(value.replace(/,/g, ''), 10)
        } else {
            if (fraction.minimumFractionDigits === 0) {
                formatted = parseInt(Math.ceil(convertedPrice), 10).toLocaleString(locale, {
                    style: 'currency',
                    currency: currencyCode,
                    ...fraction
                })
                value = parseInt(Math.ceil(convertedPrice), 10).toLocaleString({
                    style: 'currency',
                    currency: currencyCode,
                    ...fraction
                })
            } else {
                formatted = parseFloat(convertedPrice, 10).toLocaleString(locale, {
                    style: 'currency',
                    currency: currencyCode,
                    ...fraction
                })
                value = parseFloat(convertedPrice, 10).toLocaleString({
                    style: 'currency',
                    currency: currencyCode,
                    ...fraction
                })
            }
        }
    }
    return spaceBetween ? formatted.replace(value, ` ${value}`) : formatted
}

export const getConversionRate = (currencyCode = '', currencyList = {}) => {
    const { top = [], all = [] } = currencyList
    let converstionRate
    for (let i = 0; i < top.length; i++) {
        if (top[i].code === currencyCode) {
            converstionRate = top[i]
            break
        }
    }
    if (isEmpty(converstionRate)) {
        for (let i = 0; i < all.length; i++) {
            if (all[i].code === currencyCode) {
                converstionRate = all[i]
                break
            }
        }
    }
    return converstionRate
}
export function currencyFormat(price,currencies,code=""){
    const conversionRate = !isEmpty(code)
        ? getConversionRate(code, currencies.currencyList)
        : currencies.conversionRate
    const { code: currencyCode, rate = '1' } = conversionRate || {}
    let sellingRate = 1
    const { currency: originalCurrency } = getCurrencyByLocale()
    if (!isEmpty(originalCurrency)) {
        const oriCurConvRate = getConversionRate(originalCurrency, currencies.currencyList) || {}
        sellingRate = oriCurConvRate.rate
    }
    return formatCurrency({ price: price, rate, currencyCode, sellingRate, countUp: '' })

}
